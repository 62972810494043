<template>
  <div class="upload-item" @click="selectItemMethod">
    <template v-if="loading && !inWidget">
      <i class="el-icon-loading"></i>
    </template>
    <div v-else class="upload-item__thumb">
      <el-popover v-if="url" v-model="visible" placement="top" width="160">
        <p>确定删除吗？</p>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="text" @click="visible = false">取消</el-button>
          <el-button type="primary" size="mini" @click.stop="deleteMethod">确定</el-button>
        </div>
        <im-icon slot="reference" class="icon-shanchu" icon="icon-shanchu" />
      </el-popover>
      <img v-if="url" :src="url" alt="Asset" />
      <div v-if="inWidget" class="upload-item__thumb--delete del" @click="handleRemove"/>
    </div>
  </div>
</template>

<script>
import { Popover } from 'element-ui'
export default {
  name: "UploadItem",
  components: {
    ElPopover: Popover
  },
  props: {
    item: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Object,
      default() {
        return {
          uid: '',
          url: '',
          loading: false
        }
      }
    },

    inWidget: {  // 是否是在widget中编辑，是的话增加一个为空的item，方便删除
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      visible: false
    }
  },

  computed: {
    url() {
      return this.item.url
    },

    loading() {
      return this.item.loading
    },

    isHttpUrl() {
      return this.url && typeof this.url === 'string' && this.url.includes('http')
    }
  },

  methods: {
    deleteMethod() {
      this.$emit('delete', this.url)
      this.visible = false
    },
    handleRemove() {
      this.$emit('change', '')
    },
    selectItemMethod() {
      if (typeof this.url === 'string') {
        this.$emit('change', this.url)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@item-width: 67px;
@item-delete-line-w: 50px;

.upload-item {
  border: 1px @text-light-color solid;
  position: relative;
  border-radius: 4px;
  height: @item-width;
  width: @item-width;
  box-sizing: border-box;
  text-align: center;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: @primary-color;
  }

  .upload-item__thumb {
    padding: 0;
    height: 100%;
    width: 100%;
    display: block;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &--delete {
      width: @item-delete-line-w;
      height: @item-delete-line-w;
      display: inline-block;
      position: relative;

      &:hover {

        &::before,
        &::after {
          background-color: @primary-color;
        }
      }

      &::before,
      &::after {
        position: absolute;
        content: ' ';
        background-color: @text-color-secondary;
        width: 1px;
        height: @item-delete-line-w;
        left: @item-delete-line-w /2;
        top: 0px;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
  }

  .icon-shanchu {
    position: absolute;
    top: 0px;
    right: 0px;
    transform: scale(0.6, 0.6);

    &:hover {
      color: @danger-color;
    }
  }
}
</style>
