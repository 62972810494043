var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g({staticClass:"site-button",class:{
  'site-button-large': _vm.size ==='large',
  'site-button-text': _vm.type === 'text',
  'site-button-border': !!_vm.border,
  'site-button-normal': !!_vm.normal,

},attrs:{"type":_vm.htmlType,"disabled":_vm.disabled || _vm.loading}},_vm.$listeners),[(_vm.loading)?_c('im-icon',{staticClass:"icon-loading",attrs:{"icon":"icon-loading"}}):_vm._e(),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }