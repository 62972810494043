<template>
  <div class="bind-user">
    <div class="bind-user__content">
      <div v-imloading="checking" class="user-id-wrap form-wrap mt-[32px]">
        <div class="form-item">
          <div
            class="relative label"
            :style="{ color:mainTextColor }"
          >{{ $t('siteBuild.header.userId') }}</div>
          <div class="flex items-center input-wrap">
            <el-autocomplete
              ref="user"
              v-model="userId"
              class="w-full"
              :placeholder="$t('siteBuild.header.placeHolderUserID')"
              :fetch-suggestions="searchUserAccount"
              :style="mainButtonColor"
              clearable
              @keyup.enter.native="checkUser"
              @input="valueChange"
              @clear="clear"
            >
              <template slot-scope="{ item }">
                <div class="w-full flex justify-between items-center" @click.stop="handleSelectItem(item)">
                  <div class="name">
                    <span>{{ item.nickname }}，</span><span>{{ item.player_id }}，</span><span>{{ item.server }}</span>
                  </div>
                  <i class="el-icon-close hover:text-danger" @click.stop="handleRemoveUserHistoryItem(item)" />
                </div>
              </template>
            </el-autocomplete>
            <site-button class="check-btn flex-shrink-0 ml-2" :disabled="!userId" @click="checkUser">
              <i class="el-icon-check" />
            </site-button>
          </div>
        </div>
        <div v-if="model.serverEnabled" class="form-item mt-4">
          <div class="relative label" :style="{ color:mainTextColor }">{{ $t('siteBuild.header.warZone') }}</div>
          <div class="flex items-center input-wrap">
            <el-select ref="server" v-model="server" class="w-full" popper-class="server-popper" :placeholder="$t('siteBuild.header.warZone')" :popper-append-to-body="true" @change="onServerChange">
              <el-option v-for="item in $store.state.project.serverList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
        </div>
      </div>
      <div v-if="checked" class="relative user-info mt-[8px] rounded">
        <div class="user-info-item">
          <label>{{ $t('siteBuild.header.userName') }}</label>：
          <span>{{ currUserInfo && currUserInfo.name }}</span>
        </div>
        <div v-if="currUserInfo && currUserInfo.server" class="user-info-item">
          <label>{{ $t('siteBuild.header.warZone') }}</label>：
          <span>{{ currUserInfo.server }}</span>
        </div>
      </div>
      <div :class="['relative content', !isMobile ? 'mt-[32px]' : 'mt-[36px]']">
        <rich-text
          v-if="model.helperImageShow && model.helperImage"
          key="helperImageText"
          v-model="model.helperImageText"
          :editing="editing"
          :disabled="!editing"
          theme="snow"
          :mobile-layout="true"
          class="text-14 mb-1"
          :style="{ color: mainTextColor }"
        />

        <HelperImage
          v-if="model.helperImageShow"
          key="helperImageShow"
          class="hepler-image"
          :editing="editing"
          :model="model"
        />
      </div>
    </div>
    <div class="relative footer">
      <Button
        :loading="logining"
        size="large"
        :style="mainButtonColor"
        :class="[!isMobile ? 'w-full h-[56px] mt-[46px]' : 'w-full h-[48px] mt-[36px]', { disable: !checked }]"
        :disabled="bindBtnDisabled"
        @click="bindLogin"
      >{{ $t('siteBuild.header.confirm') }}</Button>
<!--      :disabled="bindBtnDisabled"-->
      <Button
        v-if="(isMobile && !siteLoginUserInfo) && !model.onlyUidLogin"
        size="large"
        :style="newSubBtnMobileColor"
        class="w-full h-[48px] mt-[24px]"
        @click="handleToLogin"
      >{{ $t('siteBuild.header.loginByEmail') }}</Button>
    </div>
  </div>
</template>

<script>
import { Autocomplete } from "element-ui";
import { mapState } from 'vuex'
import { useLoginState, LoginStateEnum } from "./useLogin";
import colorMixin from './colorMixins'
import mobileMixin from './mobileMixins'
import HelperImage from './image'
import EventTrackerMixins from '~/mixins/eventTracker'
import Button from '~/site/components/forms/button'
import richText from '~/components/richText'
import {sleep} from "~/utils";
import SiteButton from "~/site/components/forms/button.vue";

export default {
  name: 'BindUserForm',

  components: {
    SiteButton,
    [Autocomplete.name]: Autocomplete,
    Button,
    HelperImage,
    richText
  },

  mixins: [colorMixin, EventTrackerMixins, mobileMixin],

  props: {
    id: {
      type: String,
      default: () => ''
    },
    serverId: {
      type: String,
      default: ''
    },
    isBindUserLogin: { // 是否游客登录
      type: Boolean,
      default: false,
    },

    checking: { // 检查中
      type: Boolean,
      default: false,
    },

    logining: { // 登录中
      type: Boolean,
      default: false,
    },

    checked: { // 是否检查用户角色ID
      type: Boolean,
      default: false,
    },

    currUserInfo: { // 当前待绑定用户角色信息
      type: Object,
      default: () => ({}),
    },

    model: {
      type: Object,
      default() {
        return {}
      },
    },

    editing: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    const { setLoginState, } = useLoginState()

    return {
      setLoginState,
      userId: this.id,
      server: '',
      historyList: [],
      serverList: []
    }
  },

  computed: {
    ...mapState({
      siteLoginUserInfo: state => state.user.siteLoginUserInfo,
      userInfo: (state) => state.user.siteUserInfo || {}
    }),
    bindBtnDisabled() {
      let result = false
      if (this.editing) {
        result = true
      } else if (!this.checked || !this.userId) {
        result = true
      } else if ((!this.server && this.model.serverEnabled) || !this.checked) {
        result = true
      }
      return result
    },
  },

  async mounted() {
    this.getUserDetail()
    this.eventTracker(4)
    await this.getServerList()
    await this.queryHistory()
  },

  methods: {
    // autoLogin() {
    //   const query = this.$route.query
    //   this.userId = query.uid
    //   this.valueChange(this.userId)
    //   this.server = query.server_id || null
    //   this.$store.commit('user/SET_IS_AUTO_LOGIN', true)
    //   this.bindLogin()
    // },
    getUserDetail() {
      // const query = this.$route.query
      // const isAutoLogin = this.$store.state.user.isAutoLogin
      // if (query.uid && !isAutoLogin) {
      //   this.autoLogin()
      // } else {
      //   this.server = this.currUserInfo.server
      // }
      this.server = this.currUserInfo.server
      this.server && this.onServerChange(this.server)
    },
    onServerChange(value) {
      this.$emit('update:serverId', value)
      this.checkUser()
    },
    userBlur() {
      this.$nextTick(() => {
        this.$refs.user.close()
      })
    },
    async queryHistory() {
      const token = this.$utils.getSiteToken(this)
      if (!token) return
      this.$emit('show-checking')
      const [err, res] = await this.$utils.to(this.$api.siteBuild.getUserAccounts({}))
      this.$emit('hide-checking')
      if (!err) {
        const data = res instanceof Array ? res : []
        this.historyList = data.map(item => {
          const newItem = {...item}
          newItem.value = item.player_id
          return newItem
        })
      }
    },
    async getServerList() {
      if (!this.model.serverEnabled || this.$store.state.project.serverList.length) return
      this.$emit('show-checking')
      await this.$store.dispatch('project/getServerList')
      this.$emit('hide-checking')
    },
    searchUserAccount(str, cb) {
      cb(this.historyList)
    },
    handleSelectItem(item) {
      this.userId = item.player_id
      this.server = item.server
      // if (this.model.serverEnabled) {
      //   const hasServerId = item.player_id.includes('_')
      //   if (hasServerId) {
      //     const arr = item.player_id.split('_')
      //     const server = arr[arr.length - 1]
      //     const inServerList = this.$store.state.project.serverList.findIndex(tag => tag.value === server) > -1
      //     this.userId = arr[0]
      //     this.server = inServerList ? server : null
      //   } else {
      //     const server = this.serverList.findIndex(tag => tag.value === item.server) || { value: '' }
      //     this.server = server.value
      //   }
      // }
      this.valueChange(this.userId)
      this.onServerChange(this.server)
      this.userBlur()
    },
    async handleRemoveUserHistoryItem(item) {
      const index = this.historyList.findIndex(tag => tag.player_id === item.player_id)
      const [err] = await this.$utils.to(this.$api.siteBuild.removeUserHistory({ player_id: item.player_id }))
      if (!err) {
        this.historyList.splice(index, 1)
      }
    },
    clear() {
      this.$emit('clear')
      this.$nextTick(() => {
        this.$refs.user.$children[0].blur()
      })
    },
    clearData() {
      this.userId = ''
      this.server = ''
    },
    checkUser() {
      if (!this.userId) return
      if (this.model.serverEnabled) {
        this.server && this.$emit('checkUser')
      } else {
        this.$emit('checkUser')
      }
    },
    async bindLogin() {
      if (this.editing) return
      this.checkUser()
      await sleep(600)
      this.$nextTick(() => {
        this.$emit('bindLogin')
      })
    },

    valueChange(val) {
      this.$emit('update:id', (val || '').toString().trim())
    },

    handleToLogin() {
      this.setLoginState(LoginStateEnum.LOGIN)
      this.$store.commit('user/SET_SITE_BIND_USER', false)
    }
  }
}
</script>

<style lang="less">
.server-popper {
  z-index: 3011 !important;
}
.bind-user {
  @media(max-width: 1440px) {
    &__content {
      max-height: 400px;
      overflow: auto;
      position: relative;
      z-index: 3030;
      padding: 0 10px;
      &::-webkit-scrollbar-thumb {
        background-color: #DEDFE0;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.form-wrap {
  padding: 0 0 0 0;

  .form-item {
    .label {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      margin-bottom: 8px;
      color: #18171c;
    }

    .input-wrap {
      position: relative;
      border-radius: 2px;
      overflow: hidden;
      .check-btn {
        width: 46px;
        line-height: 46px;
        height: 46px;
      }
      /deep/ .el-input__inner {
        line-height: 46px;
        height: 46px;
        &:hover {
          border-color: var(--button-background-color);
        }
        &:focus {
          border-color: var(--button-background-color);
        }
      }
      .arrow {
        width: 36px;
        height: 36px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;

        div {
          width: 100%;
          height: 100%;
        }
      }

      .arrow.active {
        background: rgba(92, 111, 254, 1);

        svg {
          color: #fff;
        }
      }
    }
  }
}

.user-info {
  background: #f0f2f5;
  padding: 12px 16px;

  .user-info-item {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    margin-top: 8px;
    color: rgba(24, 23, 28, 0.66);

    &:first-child {
      margin-top: 0px;
    }
  }
}
</style>
