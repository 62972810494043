// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".assets-item__popper{background-color:#303030;border:1px solid #303030}.assets-item{border:1px solid transparent;border-radius:2px;cursor:pointer;flex-shrink:0;height:60px;overflow:hidden;position:relative;width:60px}.assets-item.active{border:1px solid #3f54f2}.assets-item__pic{height:100%;position:relative;width:100%}.assets-item__delete{align-items:center;background-color:rgba(24,23,28,.36);border-radius:2px;display:flex;height:18px;justify-content:center;position:absolute;right:2px;top:2px;width:18px}.assets-item__delete:hover{background-color:#ff6055;color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
