// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu-tree__item{margin-bottom:4px}.menu-tree__item-sub-menu .sub-menu-content .sub-menu-item{background-color:#1e1e1e;border-radius:2px;color:#fff;font-size:12px;height:36px;padding-left:34px}.menu-tree__item-sub-menu .sub-menu-content .sub-menu-item .move-btn{display:none}.menu-tree__item-sub-menu .sub-menu-content .sub-menu-item.active{background-color:#273261!important}.menu-tree__item-sub-menu .sub-menu-content .sub-menu-item:hover .move-btn{display:block}.menu-tree .add-nav{background-color:#1e1e1e;border-radius:2px;padding-left:34px;width:100%}.menu-tree .add-nav .add-icon{border-color:#7ee18b}.menu-tree .add-nav .add-icon .im-icon{color:#7ee18b}.menu-tree .add-nav:hover{background-color:hsla(0,0%,100%,.08)}.menu-tree .add-nav:hover .add-icon{background-color:#7ee18b}.menu-tree .add-nav:hover .add-icon .im-icon{color:#1e1e1e}.menu-tree .add-nav .btn{cursor:pointer;line-height:36px}.menu-tree .add-nav .btn span{color:#fff;margin-left:4px}.menu-add-popper{background-color:#1e1e1e;border:1px solid hsla(0,0%,100%,.2);border-radius:4px;left:108px;min-width:88px;width:auto}.menu-add-popper .menu-add-item{border-radius:2px;color:hsla(0,0%,100%,.6);display:block;padding:8px 16px;width:100%}.menu-add-popper .menu-add-item:hover{background:#3d46f5;color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
