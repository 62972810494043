// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu-select-popper{background-color:#303030;border:.5px solid hsla(0,0%,100%,.2);border-radius:4px;margin-top:2px!important}.menu-select-popper .popper__arrow{display:none!important}.menu-select-popper .dropdown-icon-selected{color:#fff;font-weight:800;left:8px;position:absolute;top:10px}.menu-select-popper .el-select-dropdown__list{padding:12px 8px}.menu-select-popper .el-select-dropdown__list .el-select-dropdown__item{border-radius:2px;color:#fff;font-size:14px;height:32px;line-height:32px;padding-left:30px}.menu-select-popper .el-select-dropdown__list .el-select-dropdown__item.hover,.menu-select-popper .el-select-dropdown__list .el-select-dropdown__item.selected{background:#3f54f2}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
