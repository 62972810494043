<template>
  <div v-if="visible" class="notice" :class="getClass">
    <div class="notice__content common-bg" :style="getStyle">
      <span class="fs-18px notice__content__tip">{{ model.value }}</span>
      <span v-if="!editing" class="help-color notice__content__close cursor-pointer" @click="handleClose">
        <im-icon icon="icon-clear"></im-icon>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoticeWidget',
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: true
    }
  },
  computed: {
    getStyle() {
      const { backgroundColor, backgroundImage, backgroundSize } = this.model
      return {
        backgroundColor,
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
        backgroundSize
      }
    },
    getClass() {
      const className = []
      if (!this.editing) {
        className.push('is-work')
      }
      return className
    }
  },
  methods: {
    handleClose() {
      this.visible = false
    }
  }
}
</script>

<style lang="less">
.notice {
    z-index: 10;
    width: 100%;
  &__content {
    display: flex;
    background-color: rgba(27, 28, 49, 0.9);
    padding: 12px 0;
    // color: white;
    align-items: center;
    justify-content: space-between;
    background-size: 100%;
    &__tip {
      width: 100%;
      text-align: center;
    }
    &__close {
      padding: 0 20px;
      flex-shrink: 0;
      cursor: pointer;
      font-size: 14px;
      z-index: 10;
      &:hover {
        color: white;
      }
    }
  }
  &.is-work {
    position: absolute;
  }
}
</style>
