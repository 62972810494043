<template>
  <div class="flex items-center justify-center w-full h-full">
    <ExclamationMark width="150" height="150" viewBox="0 0 50 50" />
    <div class="ml-10">
      <div class="font-bold title">System Updating</div>
      <div class="mt-6 info">To better serve you, we are updating our systems.</div>
      <a :href="backHref">Try again</a>
      <div>
        <span v-if="code">[{{ code }}] </span>
        <span v-if="request">{{ request }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ExclamationMark from '~/assets/svg/icon/unpaid-mobile.svg?inline'
import { isBuildWebSite } from '~/config'

export default {
  name: 'ForBiddenPage',

  components: {
    ExclamationMark
  },

  data() {
    return {
      isBuildWebSite,
      code: 0,
      request: '',
      backHref: ''
    }
  },

  mounted() {
    this.code = this.$route.query?.c
    this.request = this.$route.query?.u
    this.backHref = isBuildWebSite
      ? '/'
      : this.$route.query?.h || process.env.APP_URL
  }
}
</script>

<style scoped lang="less">
.title {
  font-size: 30px;
}

.info {
  font-size: 16px;
}

a {
  color: #0073f7;
  text-decoration: none;
  text-decoration-line: none;
  text-decoration-style: initial;
  text-decoration-color: initial;
}
</style>
