<template>
<div class="upload-list">
   <upload-item v-if="inWidget"
               key="default-set-empty"
               :item="{}"
               :in-widget="inWidget"
               :class="currentUrl === ''? '':''"
               @change="changeMethod"
   ></upload-item>
  <upload-item v-for="(item,index) in data"
              :key="`${item.url}_${index}`"
              :item="item"
              :class="item.url === currentUrl? 'active':''"
              @delete="deleteMethod"
              @change="changeMethod"
  ></upload-item>
</div>
</template>

<script>
import UploadItem from './uploadItem'
export default {
  name: "UploadList",
  components:{
    UploadItem,
  },
  props:{
    data: {
      type: Array,
      default(){
        return []
      }
    },
    currentUrl:{
      type: String,
      default: ''
    },
    inWidget:{  // 是否是在widget中编辑，是的话增加一个为空的item，方便删除
      type: Boolean,
      default: true,
    }
  },
  methods:{
    deleteMethod(imgUrl){
      this.$emit('delete', imgUrl)
    },
    changeMethod(imgUrl){
      this.$emit('change', imgUrl)
    }
  }
}
</script>

<style lang="less" scoped>
.upload-list{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &::after{
      content: "";
      height: 0;
      width: 67px
  }

  .active{
    border-color: @primary-color;
  }

}
</style>
