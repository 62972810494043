// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".edit-drop-down-menu{background:#2a2936;border:none;padding:8px}.edit-drop-down-menu__item{color:#fff;font-size:12px;line-height:28px;padding:0 77px 0 26px}.edit-drop-down-menu__item:hover{background:#2a2936!important;color:#fff!important}.edit-drop-down-menu .active{background:#7b66f6!important;color:#fff!important;padding-left:8px}.edit-drop-down-menu .popper__arrow{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
