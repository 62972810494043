<template>
  <base-dialog
    v-model="visible"
    class="create-page-dialog"
    title="添加页面"
    width="460px"
    :modal="true"
    :offset="[56,0,0,0]"
    :modal-append-to-body="false"
  >
    <div>
      <el-form ref="form" class="px-6 py-10" :model="form" :rules="rules">
        <el-form-item label="页面名称" prop="name">
          <base-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="描述" prop="describe">
          <base-input v-model="form.describe" :rows="4" resize="none" type="textarea" />
        </el-form-item>
        <el-form-item class="link" label="页面链接" prop="link">
          <base-input v-model="form.link" placeholder="请输入">
            <template slot="prepend" class="pr-0">{{ `https://${domain}/` }}</template>
          </base-input>
        </el-form-item>
      </el-form>
      <div class="footer py-4 px-6 text-right border-t border-fillColor-4">
        <button class="h-[36px] w-[80px] px-4 border border-lineColor-3 rounded-sm text-14 mr-3" @click="handleCancel">取消</button>
        <button class="h-[36px] w-[80px] px-4 bg-primary rounded-sm text-14 text-textColor-5" @click="handleSubmit">保存</button>
      </div>
    </div>
  </base-dialog>
</template>

<script>
import { Form, FormItem, Message } from 'element-ui'
import { PatternEnum } from '~/enums/pattern'
import BaseDialog from '~/components/base/baseDialog'
import BaseInput from "~/components/base/baseInput";
export default {
  components: {
    BaseDialog,
    ElForm: Form,
    ElFormItem: FormItem,
    BaseInput
  },
  props: {
    domain: {
      type: String,
      default: ''
    }
  },
  data() {
    const checkLink = (rule, value, callback) => {
      const reg = PatternEnum.PAGE_LINK
      if (reg.test(value)) {
        return callback()
      } else {
        return callback(new Error('必须为字母，长度最小为2且不超过10'))
      }
    }
    return {
      visible: false,
      form: {
        name: '',
        describe: '',
        link: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入页面名称' }
        ],
        describe: [
          { required: false, message: '请输入页面描述' }
        ],
        link: [
          { required: true, validator: checkLink }
        ]
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    handleCancel() {
      this.visible = false
    },
    checkForm() {
      return new Promise((resolve) => {
        this.$refs.form.validate(flag => {
          resolve(flag)
        })
      })
    },
    async handleSubmit() {
      const isRight = await this.checkForm()
      if (!isRight) {
        return
      }
      const { name, link, describe } = this.form
      const params = {
        site_builder_id: this.$store.state.project.info.id,
        name,
        link,
        desc_content: describe
      }
      const [err, res] = await this.$utils.to(this.$api.siteBuild.createPage(params))
      if (!err) {
        Message.success('页面添加成功')
        const page = {
          ...res,
          id: res.id.toString(),
          value: res.name
        }
        this.SitePage.append(page)
        this.handleCancel()
      } else {
        Message.error(err)
      }
    }
  }
}
</script>

<style lang="less">
.create-page-dialog {
  .el-form-item__label{
    color: @menu-bg;
  }
  .el-form-item {
    color: @menu-bg;
    .el-textarea__inner, .el-input__inner {
      color: @menu-bg;
      border-color: @normal-btn-border;
      &:focus {
        border-color: @primary-1;
        color: @menu-bg;
      }
    }
    &.link {
      .el-input-group__prepend {
        background: #EBECF0;
      }
      .el-input__inner {
        padding-left: 10px;
      }
    }
  }
}
</style>
