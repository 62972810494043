// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu-tab-btn-wrap{background:#282944;border-radius:2px;box-sizing:border-box;color:hsla(0,0%,100%,.6);display:flex;height:32px;overflow:hidden}.menu-tab-btn-wrap .menu-tab-btn__item{display:inline-block;flex:1;font-size:12px;height:100%;text-align:center}.menu-tab-btn-wrap .menu-tab-btn__item--active{background:#3d46f5;color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
