<template>
  <svg class="im-icon"><use :xlink:href="`#${icon}`" /></svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="less">

</style>
