// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".activity-progress{position:relative}.activity-progress.mobile .activity-progress__node{height:180px;padding-bottom:44px}.activity-progress__bar{border-radius:16px;flex-shrink:0;height:16px;overflow:hidden;position:relative;width:100%}.activity-progress__info{left:50%;position:absolute;top:50%;transform:translate(-50%,-50%);z-index:10}.activity-progress__node{align-items:center;display:flex;height:156px;justify-content:center;position:absolute;top:12px;width:100%}.activity-progress__node .node-text{padding:4px 16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
