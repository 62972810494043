<template>
  <div class="search-input">
    <el-autocomplete :value="value" class="w-full" :fetch-suggestions="querySearch" size="small"
      popper-class="search-input-popper" :clearable="clearable" @input="onInput" @select="onSelect"
      @change="onInput"></el-autocomplete>
  </div>
</template>

<script>
import { Autocomplete } from 'element-ui'
export default {
  components: {
    ElAutocomplete: Autocomplete
  },
  props: {
    clearable: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    },
    suggestList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      }
    },
    querySearch(queryString, cb) {
      const restaurants = this.suggestList;
      const results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      cb(results);
    },
    onInput(val) {
      this.$emit('input', val)
    },
    onSelect(item) {
      this.$emit('input', item.value)
      this.$emit('select', item)
    }
  }
}
</script>

<style lang="less">
.search-input-popper {
  background-color: @popper-bg;
  border-color: @popper-bg;
  padding: 0 8px;

  div.popper__arrow {
    display: none;
  }

  li {
    color: white;
  }

  li.hover {
    background-color: @primary-color !important;
    color: white;
  }

  li:hover {
    background-color: @primary-color;
    color: white;
  }

  li.is-disabled:hover {
    background-color: transparent;
  }
}

.search-input {
  .el-input__inner {
    border: 1px solid transparent;
    background-color: @fill-color-2;
    color: @text-5;
  }
}
</style>
