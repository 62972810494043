// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".edit-widget-columns{background-color:#1e1e1e;border-right:1px solid #292929;flex-shrink:0;height:calc(100vh - 56px);padding:0 0 30px;position:relative;width:70px}.edit-widget-columns .item{align-items:center;background-color:#0a0a0a;border-bottom:1px solid transparent;border-top:1px solid transparent;color:#fff;cursor:pointer;display:flex;flex-direction:column;height:100px;justify-content:center;position:relative}.edit-widget-columns .item.active{background-color:#1e1e1e}.edit-widget-columns .item.active:after{background-color:#1e1e1e;content:\"\";height:105px;position:absolute;right:-1px;top:-3px;width:1px}.edit-widget-columns .item.next{border-top:1px solid #292929;border-top-right-radius:4px}.edit-widget-columns .item.prev{border-bottom:1px solid #292929;border-bottom-right-radius:4px}.edit-widget-columns .item .pic{background-color:#fff;border-radius:2px;height:28px;width:28px}.edit-widget-columns .item .name{color:#c4c5c8;font-size:12px;margin-top:5px}.edit-widget-columns .bottom-setting{background-color:#0a0a0a;bottom:0;display:flex;flex-direction:column;height:calc(100vh - 356px);justify-content:flex-end;left:0;position:absolute;width:100%}.edit-widget-columns .bottom-setting.active{border-top:1px solid #292929;border-top-right-radius:4px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
