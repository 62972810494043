// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-edit__right{box-sizing:border-box;height:100%;overflow:auto;padding:16px;position:relative}.ui-edit__right .edit-menu-wrap{background-color:#1e1e1e;height:100%;left:0;overflow-y:auto;padding:16px;position:absolute;top:0;width:100%;z-index:2000}.ui-edit__right .menu-item:first-child{padding-top:0}.ui-edit__right .edit-content-wrap{bottom:48px;left:0;overflow-y:auto;padding-bottom:16px;position:absolute;top:0;width:100%}.ui-edit__right .edit-title{box-sizing:border-box;color:#fff;display:flex;font-size:18px;height:18px;justify-content:space-between;line-height:18px;padding-bottom:40px;padding-left:12px;position:relative}.ui-edit__right .edit-title:before{background:#3d46f5;content:\" \";height:14px;left:0;position:absolute;top:1px;width:4px}.remove-block-dialog .el-dialog__header{padding-top:24px}.remove-block-dialog .divider{border-top:1px solid hsla(0,0%,100%,.08)}.remove-block-dialog .remove-block__body{padding:20px;text-align:center}.remove-block-dialog .remove-block__body .warning-title{color:#0a0a0a;line-height:24px}.remove-block-dialog .remove-block_footer{display:flex;justify-content:flex-end;padding:16px 20px;text-align:center}.remove-block-dialog .remove-block_footer button{border-radius:2px;box-sizing:border-box;font-size:14px;height:36px;line-height:36px;margin-left:24px;padding:0 16px;text-align:center}.remove-block-dialog .remove-block_footer button:first-child{margin-left:0}.remove-block-dialog .remove-block_footer .normal-btn{background-color:#fff;border:1px solid #e8e8ea;color:#303030}.remove-block-dialog .remove-block_footer .remove-btn{background:#ff6055;color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
