<template>
  <div class="edit-background-menu-wrap">
    <menu-title :close="close">{{$t('head.backImg')}}</menu-title>
    <menu-item :label="$t('edit.peiSe')">
      <div class="flex color-list">
        <div v-for="(item, index) in colorList" :key="index" class="cursor-pointer color-item" :style="{
          backgroundImage: model.backgroundImage?`url(${model.backgroundImage})`: 'none',
          backgroundSize: model.backgroundSize
        }"
          @click="()=>{
           model.setBackgroundColor(item.value)
        }">
          <div class="color-bg" :style="{
            backgroundColor: item.value
          }"></div>
        </div>
        <div class="relative flex items-center justify-center cursor-pointer color-item set" @click.stop.prevent="handleShowColor">
          <set-svg class="fill-white" />
          <ColorPicker ref="color" v-model="model.backgroundColor" :show-alpha="true"
                       :index="1.6"
                       @change="(val)=>{
                         model.setBackgroundColor(val)
                       }"></ColorPicker>
        </div>
      </div>
    </menu-item>
    <!-- 设置图像包含模式-->
    <menu-item :label="$t('edit.imageColor')">
      <button v-for="bgSize in bgSizeList" :key="bgSize" class="bg-size-item" :class="{'bg-size-item-active': model.backgroundSize === bgSize}"
           @click="changeBackGroundSizeMethod(bgSize)">  {{$t('edit.' + bgSize)}} </button>
    </menu-item>
    <menu-item :label="$t('model.assets')">
      <assets-material  v-model="model.backgroundImage" in-widget :value="model.backgroundImage" :limit="5" @change="(url)=>{
        model.setBackgroundImage(url)
      }"></assets-material>
    </menu-item>
  </div>
</template>

<script>
import tinycolor from 'tinycolor2'
import menuTitle from "~/components/menu/menuTitle";
import menuItem from "~/components/menu/menuItem";
import ColorPicker from '~/components/colorPickers/index'
// import uploadBlock from "~/components/common/uploadBlock";
import setSvg from '~/assets/svg/icon/set.svg';
import AssetsMaterial from '~/site/components/assetsMaterial'
import { BackgroundSizeList } from '~/enums/DomEnum'

export default {
  name: 'EditMenuBackground',
  components: {
    menuTitle,
    menuItem,
    setSvg,
    ColorPicker,
    AssetsMaterial,
    // uploadBlock
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      bgSizeList: BackgroundSizeList
    }
  },
  computed:{
    colorList(){
      const val = this.model.backgroundColor || '#FFF'
      const color = tinycolor(val)
      return [0 ,0.4, 0.7, 1].map(item=>{
        return {
          value :color.setAlpha(item).toRgbString()
        }
      })
    }
  },
  methods: {
    blur(){},
    changeBackGroundSizeMethod(value){
      if(this.model.setBackgroundSize){
        this.model.setBackgroundSize(value)
      }
    },
    close() {
      this.SiteMenu.close()
    },
    handleShowColor() {
      this.$refs.color.handleShowColor()
      //  this.$refs.color.$el.querySelector('.el-color-picker__trigger').click()
    },
    handleTest() {
      this.$parent.selectSubmenu('backgroundTest')
    }
  }
}
</script>

<style lang="less">
.edit-background-menu-wrap {
  .site-color-picker {
    position: relative;
    width: 100%;
    height: 33px;
    border-radius: 2px;
    border: 0.5px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 16px;
  }
  .bg-size-item{
     display: block;
     width: 100%;
     height: 32px;
     line-height: 32px;
     color: @text-color-secondary;
     background-color:@widget-menu-bg;
     text-align: center;
     border-radius: 2px;
  }
  .bg-size-item-active{
    color: @text-color;
    background-color: @primary-color-alpha-1;
  }
  .color-list {
    gap: 8px;

    .color-item {
      width: 33px;
      height: 33px;
      border-radius: 2px;
      border: 0.5px solid rgba(255, 255, 255, 0.3);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 50%;
      position: relative;
      .color-bg{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
      }
      &:hover {
        border-color: @primary-color;
      }

      &.set {
        background-color: #333;
      }

      .color-picker {
        pointer-events: none;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
