<template>
  <div
    class="toggle-visible w-[28px] h-full absolute right-0 top-0 flex items-center justify-center text-textColor-7 hover:text-textColor-5 hover:bg-fillColor-2"
    @click.stop="handleToggleVisible">
    <component :is="visible ? 'icon-show' : 'icon-hide'" class="text-white"></component>
  </div>
</template>
<script>
import ImIcon from "~/components/common/ImIcon"
import iconShow from '@/assets/svg/icon/iconShow.svg'
import iconHide from '@/assets/svg/icon/iconHide.svg'

export default {
  components: {
    ImIcon,
    iconHide,
    iconShow
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleToggleVisible() {
      this.$emit('visible-change')
    },
  }
}
</script>
<style lang="less" scoped>

</style>
