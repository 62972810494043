<template>
  <el-dialog class="convert-key-confirm" :class="{ 'is-mobile': device === DeviceEnum.MOBILE }" :visible.sync="visible" width="720px">
    <div class="confirm-content">
      <div class="title flex items-center justify-center w-full">
        <i class="el-icon-warning text-24 mr-2" />
        <span class="text-20 info-text">{{ $t('siteBuild.convertTip1') }}</span>
      </div>
      <div class="mt-8 confirm-content__info">
        <div class="text-center">
          <span class="font-bold text-18 mr-5">{{ $t('siteBuild.header.userName') }}:</span>
          <span>{{ userInfo?.name }}</span>
        </div>
        <div class="text-center mt-2">
          <span class="font-bold text-18 mr-5">{{ $t('siteBuild.header.warZone') }}:</span>
          <span>{{ userInfo?.server }}</span>
        </div>
      </div>
      <div class="confirm-content__gift flex overflow-auto justify-center my-6 gap-5">
        <div v-for="(item, index) in detail.gift" :key="index" class="item flex items-center flex-col justify-center flex-shrink-0">
          <img class="w-[60px] h-[60px]" :src="item.gift_goods_logo" alt="">
          <div>{{ item.num }}</div>
        </div>
      </div>
      <div class="confirm-content__footer text-center mt-8">
        <el-button class="w-auto mx-4" @click="handleCancel">{{ $t('siteBuild.cancel') }}</el-button>
        <site-button class="w-auto min-w-[80px] mx-4" :loading="loading" @click="handleSubmit">{{ $t('siteBuild.convert') }}</site-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {Dialog, Message} from "element-ui"
import {mapState} from "vuex"
import SiteButton from "~/site/components/forms/button.vue"
import {DeviceEnum} from "~/enums/deviceEnum";

export default {
  name: 'ConvertKeyConfirm',
  components: {
    SiteButton,
    [Dialog.name]: Dialog
  },
  props: {
    device: {
      type: String,
      default: ''
    },
    detail: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      visible: false,
      loading: false
    }
  },
  computed: {
    DeviceEnum() {
      return DeviceEnum
    },
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      projectId: (state) => state.project.projectId,
    })
  },
  methods: {
    handleCancel() {
      this.visible = false
    },
    init() {
      this.visible = true
    },
    async handleSubmit() {
      const params = {
        project_id: this.projectId,
        redemption_code: this.detail.redemption_code
      }
      this.loading = true
      const [err] = await this.$utils.to(this.$api.convertKey.convert(params))
      this.loading = false
      if (!err) {
        Message.success('Success')
        this.handleCancel()
      } else {
        Message.error(err)
      }
    }
  }
}
</script>


<style lang="less">
.convert-key-confirm {
  .info-text {
    word-break: break-word;
  }
  &.is-mobile {
    .el-dialog {
      width: 100% !important;
    }
  }
  .el-icon-warning {
    color: @warning-color;
  }
}
</style>
