<template>
  <div class="im-input-number text-black" :class="[size, disabled ? 'is-disabled' : '']">
    <div class="btn" :class="{ 'disabled': prevDisabled }" @click="handleSub">
      <i class="el-icon-minus" />
    </div>
    <div class="number">{{ number }}</div>
    <div class="btn" :class="{ 'disabled': nextDisabled }" @click="handleAdd">
      <i class="el-icon-plus" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImInputNumber',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    number: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 1
    },
    size: {
      type: String,
      default: 'small'
    },
    max: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
    }
  },
  computed: {
    nextDisabled() {
      return this.number >= this.max
    },
    prevDisabled() {
      return this.number <= this.min
    }
  },
  methods: {
    handleSub() {
      if (this.prevDisabled) return
      this.$emit('change', this.number - 1)
    },
    handleAdd() {
      if (this.nextDisabled) return
      this.$emit('change', this.number + 1)
    }
  }
}
</script>

<style lang="less">
.im-input-number {
  display: flex;
  align-items: center;
  border: 1px solid @border-color-1;
  border-radius: 2px;
  &.is-disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  &.small {
    width: 130px;
    .btn {
      width: 37px;
      height: 36px;
    }
  }
  &.mini {
    width: 86px;
    .btn {
      width: 26px;
      height: 26px;
    }
    .number {
      font-size: 12px;
    }
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
    &:first-of-type {
      border-right: 1px solid @border-color-1;
    }
    &:last-of-type {
      border-left: 1px solid @border-color-1;
    }
    &:hover {
      background-color: @border-color-1;
    }
    &.disabled {
      color: @text-3-hex;
      cursor: not-allowed;
      &:hover {
        background-color: unset;
      }
    }
  }
  .number {
    flex: 1;
    text-align: center;
  }
}
</style>
