<template>
  <div class="activity-progress">
<!--    <div v-for="(item, index) in data" :key="item.virtual_currency_val" class="activity-progress__node" :style="{ top: (index  * (isMobile ? 180 : 156)) + 'px' }">-->
<!--      <bg-style class="relative z-10 node-text" :bg="model.progressNodeBackground">-->
<!--        <span class="relative">{{ item.virtual_currency_val }}</span>-->
<!--      </bg-style>-->
<!--    </div>-->
    <div class="activity-progress__info">{{ infoText }}</div>
    <bg-style class="activity-progress__bar" :bg="model.progressBackground">
      <bg-style class="h-full" :style="{ width: percent + '%' }" :bg="model.progressInnerBackground" />
    </bg-style>
  </div>
</template>

<script>
export default {
  name: 'ActivityProgress',
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    percent: {
      type: [String, Number],
      default: ''
    },
    infoText: {
      type: String,
      default: ''
    },
    model: {
      type: Object,
      default() {
        return {
        }
      }
    }
  },
  data() {
    return {
    }
  },
  computed: {
  }
}
</script>

<style lang="less">
.activity-progress {
  position: relative;
  &.mobile {
    .activity-progress__node {
      height: 180px;
      padding-bottom: 44px;
    }
  }
  &__bar {
    width: 100%;
    height: 16px;
    border-radius: 16px;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
  }
  &__info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
  &__node {
    position: absolute;
    height: 156px;
    width: 100%;
    top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    .node-text {
      padding: 4px 16px;
    }
  }
}
</style>
