<template>
  <div class="device-tab">
    <div class="device-tab__thumb" :style="thumbStyle" />
    <div v-for="(item, index) in deviceList" :key="index" class="item cursor-pointer" @click="handleClick(index)">
      <im-icon class="text-white" :icon="iconMap[item.value]" />
      <span class="ml-1">{{ item.label }}</span>
    </div>
  </div>
</template>
<script>
import { DeviceList } from "~/settings/deviceSetting";

export default {
  name: 'DeviceTab',
  data() {
    return {
      deviceList: DeviceList,
      left: '',
      iconMap: {
        mobile: 'icon-yidongduan',
        desktop: 'icon-zhuomianduan'
      }
    }
  },
  computed: {
    thumbStyle() {
      return {
        transform: `translateX(${this.left}px)`
      }
    }
  },
  methods: {
    handleClick(index) {
      this.left = index === 0 ? 0 : 80
      this.$emit('change', this.deviceList[index]?.value)
    }
  }
}
</script>

<style lang="less">
.device-tab {
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: @border-radius-base;
  background-color: @fill-color-4;
  position: relative;
  &__thumb {
    background-color: @primary-1;
    height: 24px;
    width: 80px;
    position: absolute;
    border-radius: @border-radius-base;
    transition: all 0.24s ease;
  }
  .item {
    width: 80px;
    height: 24px;
    font-size: 13px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
}
</style>
