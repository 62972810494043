<template>
  <div v-if="isSupported" class="eye-dropper text-white" @click="handleOpen">
    <icon-drop class="text-18 cursor-pointer" />
<!--    <im-icon class="text-18 cursor-pointer" icon="icon-straw"></im-icon>-->
  </div>
</template>

<script>
import tinycolor from 'tinycolor2'
import iconDrop from '@/assets/svg/icon/iconDrop.svg'
export default {
  components: {
    iconDrop
  },
  data() {
    return {
    }
  },
  computed: {
    isSupported() {
      return Boolean(typeof window !== 'undefined' && 'EyeDropper' in window)
    }
  },
  created() {
  },
  mounted() {
    this.$emit('mount')
  },
  methods: {
    init() {
    },
    async handleOpen() {
      const eyeDropper= new window.EyeDropper()
      const result = await eyeDropper.open().catch(err => {
        // eslint-disable-next-line no-console
        console.warn(err)
      })
      const color = tinycolor(result?.sRGBHex || '#fff')
      const rgba = color.toRgb()
      if (result?.sRGBHex) {
        this.$emit('change', `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.eye-dropper {
}
</style>
