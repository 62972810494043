// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".help-popper.is-dark{background-color:#303030;opacity:1!important}.help-popper.is-dark .popper__arrow,.help-popper.is-dark .popper__arrow:after{border-bottom-color:#303030!important;border-top-color:#303030!important}.menu-btn-wrap{color:hsla(0,0%,100%,.7)}.menu-btn-wrap .svg-icon{fill:currentColor;margin-right:8px}.menu-btn-wrap.ghost,.menu-btn-wrap.ghost-copy{border:1px dashed hsla(0,0%,100%,.3);border-radius:4px;padding:8px 16px}.menu-btn-wrap.ghost-copy:hover,.menu-btn-wrap.ghost:hover{border-color:hsla(0,0%,100%,.6)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
