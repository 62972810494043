<template>
  <div class="store-widget-edit-wrap">
    <!-- 背景设置 -->
    <menu-item :label="$t('head.backImg')" class="menu-item-switch" pt="0px">
      <menu-fill :target="model" eye-key="backgroundColorShow"/>
      <menu-background-image :target="model" class="mt-2" color-key="backgroundImage" eye-key="backgroundImageShow"/>
    </menu-item>
    <!-- 背景模糊 -->
    <menu-item :label="$t('menu.backgroundBlur')" class="menu-item-switch">
      <Slider v-model="model.backgroundBlur" target-key="backgroundBlur"/>
    </menu-item>
    <menu-divider />
    <menu-item label="购物车">
      <menu-switch v-model="model.shopCartEnabled" label="是否开启购物车" target-key="shopCartEnabled"></menu-switch>
    </menu-item>
    <menu-item v-if="model.shopCartEnabled" label="购物车按钮颜色" size-tip="(建议比例4:3)">
      <menu-fill :target="model.shopCartColor" class="mt-2" target-key="shopCartColor"/>
    </menu-item>
    <menu-item v-if="model.shopCartEnabled" label="购物车按钮背景" size-tip="(建议比例4:3)">
      <menu-background-image :target="model.shopCartBackground" target-key="shopCartBackground"/>
      <menu-fill :target="model.shopCartBackground" class="mt-2" target-key="shopCartBackground"/>
    </menu-item>

    <!-- 布局 -->
    <menu-item :label="$t('menu.layout')" class="menu-item-switch" pt="0px">
      <menu-switch v-model="model.titleVisible" label="标题" target-key="titleVisible" />
      <menu-switch v-model="model.groupNameVisible" class="mt-2" label="商品分类名称" target-key="groupNameVisible" />
    </menu-item>
    <menu-item label="商品卡片背景" size-tip="(建议比例2:4)">
      <menu-background-image :target="model.goodsBackground" class="mt-2" target-key="goodsBackground"/>
      <menu-fill :target="model.goodsBackground" class="mt-2" target-key="goodsBackground"/>
    </menu-item>
    <menu-item label="商品Logo背景" size-tip="(建议尺寸:280 * 240)">
      <menu-background-image :target="model.logoBackground" class="mt-2" target-key="logoBackground"/>
      <menu-fill :target="model.logoBackground" class="mt-2" target-key="logoBackground"/>
    </menu-item>
    <menu-item label="商品背景圆角" class="menu-item-switch">
      <Slider v-model="model.goodsBorderRadius" target-key="goodsBorderRadius"/>
    </menu-item>

    <menu-item label="按钮背景" size-tip="(建议尺寸:278 * 60)">
      <menu-background-image :target="model.buyBackground" class="mt-2" target-key="buyBackground"/>
      <menu-fill :target="model.buyBackground" class="mt-2" target-key="buyBackground"/>
    </menu-item>
    <menu-item label="名称颜色">
      <menu-fill :target="model" color-key="goodsTitleColor" eye-key="goodsTitleColorShow"
      />
    </menu-item>
<!--    <menu-item label="描述文本颜色">-->
<!--      <menu-fill :target="model" color-key="goodsDescriptionColor" eye-key="goodsDescriptionColorShow" />-->
<!--    </menu-item>-->
    <menu-item label="价格颜色">
      <menu-fill :target="model" color-key="goodsPriceColor" eye-key="goodsPriceColorShow" />
    </menu-item>

    <menu-divider></menu-divider>

    <!-- 新增商店板块 -->
    <store-good :editing="editing" :model="model" />

    <!-- 创建区块副本 -->
    <menu-button :help-text="$t('menu.copyBlock')" class="mt-4" icon="add" type="ghost-copy" @click.native.stop="handleClone">{{ $t('menu.createCopy') }}</menu-button>
    <remove-btn class="mt-4"/>
  </div>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import {StoreWidgetModel} from '../../model/index'
import MenuItem from "~/components/menu/menuItem";
import MenuSwitch from "~/components/menu/menuSwitch";
import StoreGood from "~/components/menu/storeGood";
import MenuButton from "~/components/menu/menuButton";
import menuFill from "~/components/menu/menuFill";
import menuBackgroundImage from "~/components/menu/menuBackgroundImage";
import MenuDivider from "~/components/menu/divider"
import Slider from "~/components/menu/menuSlider";
import StoreLayoutMethod from "~/components/menu/storeLayoutMethod";
// import StoreGoodsLayout from "~/components/menu/storeGoodsLayout";
import RemoveBtn from '~/components/common/RemoveBtn.vue'
import {insertStep} from "~/utils/event";

export default {
  name: "StoreWidgetEdit",
  components: {
    RemoveBtn,
    MenuItem,
    MenuSwitch,
    StoreGood,
    MenuButton,
    menuFill,
    menuBackgroundImage,
    MenuDivider,
    Slider,
    StoreLayoutMethod,
    // StoreGoodsLayout
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    extend: {
      type: Object,
      default: () => {
        return {}
      }
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menuList: []
    }
  },

  methods: {
    switchChange(value, item) {
      const layout = cloneDeep(this.model.layout)
      item.value = value
      insertStep(this, ['layout'], layout)
    },
    handleClone() {
      this.$emit('clone', new StoreWidgetModel(this.model.__getOptions()))
    }
  }
}
</script>

<style lang="less">
.store-rich-text {
  position: relative;
  margin-bottom: 16px;

  .ql-editor.ql-blank::before {
    color: rgba(255, 255, 255, 0.3);
    font-style: normal;
  }

  .ql-bubble {
    .ql-tooltip {
      position: absolute;
      top: 38px !important;
      left: 0 !important;
      background: #151724 !important;
      border: 0.5px solid rgba(255, 255, 255, 0.3);
      z-index: 2021;
    }
  }

  .rich-text {
    //border: 0.5px solid rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    // overflow: hidden;
    // height: 44px;
    // margin-bottom: 16px;
    &:hover {
      box-shadow: none;
    }
  }
}
</style>
