// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".device-tab{align-items:center;background-color:hsla(0,0%,100%,.12);border-radius:2px;display:flex;padding:4px;position:relative}.device-tab__thumb{background-color:#3f54f2;border-radius:2px;height:24px;position:absolute;transition:all .24s ease;width:80px}.device-tab .item{align-items:center;color:#fff;display:flex;font-size:13px;height:24px;justify-content:center;width:80px;z-index:10}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
