<template>
  <div class="site-ui-tag" :class="'ui-tag-' + type" v-bind="$attrs" @click.stop.prevent="itemClick">
    {{ label }}
    <CloseSvg v-if="type === 'active'" class="tag-icon close" @click.stop.prevent="()=>{$emit('close',value)}"/>
    <AddSvg v-if="type === 'default'" class="tag-icon" @click.stop.prevent="()=>{$emit('add',value)}"></AddSvg>
  </div>
</template>
<script>
import CloseSvg from '~/assets/svg/icon/close-white-small.svg'
import AddSvg from '~/assets/svg/icon/add-small.svg'

export default {
  name: "UiTag",
  components: {
    CloseSvg,
    AddSvg,
  },
  props: {
    type: {
      type: String, // disabled, active,
      default: 'default'
    },
    value: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number, // disabled, active,
      default: ''
    },
    label: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: 0
    },
  },
  methods: {
    close() {
      this.$emit('close', this.value)
    },
    add() {
      this.$emit('add', this.value)
    },
    itemClick() {
      if (this.type === 'active') {
        this.close()
      } else {
        this.add()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.site-ui-tag {
  display: inline-block;
  border: 1px solid @text-color-alpha-3;
  height: 24px;
  text-align: center;
  padding: 2px 6px;
  font-size: 12px;
  box-sizing: border-box;
  color: @text-color-alpha-3;
  border-radius: 2px;

  &:hover {
    color: @white-color;
    border-color: @white-color;
  }

  .tag-icon {
    margin-left: 6px;
    position: relative;
    top: -2px;
  }

  .close {
    top: -1px;
  }
}

.ui-tag-default {
  color: @text-color-alpha-3;

}

.ui-tag-active {
  color: @white-color;
  background: @primary-color;
  border-color: @primary-color;

}
</style>
