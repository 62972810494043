// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gears_wrap_desk .gears-content{width:100%}.gears_wrap_desk .activity_info{font-family:PingFang SC;font-size:20px;line-height:30px;margin-bottom:16px}.gears_wrap_desk .gift_info{align-items:center;display:flex;justify-content:space-between}.gears_wrap_desk .gift_info .gift_list{-moz-column-gap:24px;column-gap:24px}.gears_wrap_desk .gift_info .gift_list .gift_list_row_item{align-items:center;border-radius:8px;flex-direction:column;height:86px;justify-content:center;overflow:hidden;padding-top:5px;position:relative;width:86px}.gears_wrap_desk .gift_info .sum_btn{align-items:center;border:none;border-radius:2px;display:flex;height:36px;justify-content:center;min-width:80px;padding:0 12px}.gears_wrap_desk .gears-wrap-list{padding:24px}.gears_wrap_mobile .gears-content{width:100%}.gears_wrap_mobile .activity_info{font-family:PingFang SC;font-size:14px;line-height:22px;margin-bottom:8px}.gears_wrap_mobile .gears-wrap-list{padding:12px}.gears_wrap_mobile .gift_info{display:flex;flex-direction:column}.gears_wrap_mobile .gift_info .gift_list{gap:8px}.gears_wrap_mobile .gift_info .gift_list .gift_list_row_item{align-items:center;flex-direction:column;height:80px;justify-content:center;padding-top:5px;position:relative;width:80px}.gears_wrap_mobile .gift_info .item-gift{margin-top:16px;padding-left:0!important}.gears_wrap_mobile .gift_info .sum_btn{align-items:center;border:none;border-radius:2px;display:flex;height:32px;justify-content:center;min-width:72px;padding:0 8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
