// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notice{width:100%;z-index:10}.notice__content{align-items:center;background-color:rgba(27,28,49,.9);background-size:100%;display:flex;justify-content:space-between;padding:12px 0}.notice__content__tip{text-align:center;width:100%}.notice__content__close{cursor:pointer;flex-shrink:0;font-size:14px;padding:0 20px;z-index:10}.notice__content__close:hover{color:#fff}.notice.is-work{position:absolute}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
