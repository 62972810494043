<template>
  <div class="is_in_wechat fixed top-0 bottom-0 left-0 right-0 flex items-center flex-col">
    <img src="../../assets/img/guide_more.png" class="mt-8" alt />
    <div class="text-white text-16 leading-6 font-normal">
      <div class="mt-3">1. 点击右上角 “更多” 按钮</div>
      <div class="mt-3">2. 选择 “在浏览器打开”</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckWeChat'
}
</script>

<style lang="less" scoped>
.is_in_wechat {
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: PingFang SC;
  > img {
    margin-left: 40%;
  }
}
</style>
