<template>
  <div class="activity-widget-edit-wrap">
    <!-- 类型 -->
    <menu-item :label="$t('menu.activityType')">
      <im-select v-model="model.activityType" class="w-full"  :options="typeOptions" size="small" />
    </menu-item>
    <!-- 背景图片 -->
    <menu-item :label="$t('head.backImg')" size-tip="(建议比例4:3)">
      <menu-fill :target="model.background" target-key="background" />
      <menu-background-image class="mt-2" :target="model.background" target-key="background" />
    </menu-item>
    <!-- 布局 -->
    <menu-item :border-bottom="true" class="menu-item-switch pt-4 pb-3" :label="$t('menu.layout')">
      <div class="switchs">
        <menu-switch v-model="model.titleVisible" label="标题" target-key="titleVisible" />
        <div v-for="s in model.layout" :key="s.key">
          <menu-switch
            v-model="s.value"
            :label="$t(s.label)"
            margin-bottom="8px"
            @change="e=>switchChange(e,s)"
          ></menu-switch>
        </div>
      </div>
    </menu-item>
    <!-- 未达标按钮背景图 -->
    <menu-item class="pt-3" :label="$t('menu.nqBtnImg')" size-tip="(建议比例2:1)">
      <menu-fill :target="model.nqBtnBackground" target-key="nqBtnBackground" />
      <menu-background-image class="mt-2" :target="model.nqBtnBackground" target-key="nqBtnBackground" />
    </menu-item>
    <menu-item class="pt-3" :label="$t('menu.nqBtnColor')">
      <menu-fill :target="model" target-key="nqBtnColor" color-key="nqBtnColor" eye-key="nqBtnColorShow" />
    </menu-item>
    <!-- 领取按钮背景图 -->
    <menu-item class="pt-3" :label="$t('menu.getBtnImg')" size-tip="(建议比例2:1)">
      <menu-fill :target="model.getBtnBackground" target-key="getBtnBackground" />
      <menu-background-image class="mt-2" :target="model.getBtnBackground" target-key="getBtnBackground" />
    </menu-item>
    <!-- 领取按钮文字 -->
    <menu-item class="pt-3" :label="$t('menu.getBtnColor')">
      <menu-fill :target="model" color-key="getBtnColor" eye-key="getBtnColorShow" />
    </menu-item>
    <!-- 已领取按钮背景图 -->
    <menu-item class="pt-3" :label="$t('menu.receivedBtnImg')" size-tip="(建议比例2:1)">
      <menu-fill :target="model.receivedBtnBackground" target-key="receivedBtnBackground" />
      <menu-background-image class="mt-2" :target="model.receivedBtnBackground" target-key="receivedBtnBackground" />
    </menu-item>
    <!-- 已领取按钮文字 -->
    <menu-item class="pt-3" :label="$t('menu.receivedBtnColor')">
      <menu-fill :target="model" color-key="receivedBtnColor" eye-key="receivedBtnColorShow" />
    </menu-item>
    <!-- 已领取奖励遮罩 -->
    <menu-item class="pt-3" :label="$t('menu.receivedMask')" size-tip="(建议比例4:3)">
      <menu-fill :target="model.receivedMaskBackground" target-key="receivedMaskBackground" />
      <menu-background-image class="mt-2" :target="model.receivedMaskBackground" target-key="receivedMaskBackground" />
    </menu-item>
    <!-- 数字颜色 -->
    <menu-item class="pt-3" :label="$t('menu.numberColor')">
      <menu-fill :target="model" color-key="numberColor" eye-key="numberColorShow" />
    </menu-item>
    <!-- 奖励单行背景 -->
    <menu-item class="pt-3" :label="$t('menu.giftBgImg')" size-tip="(建议比例4:1)">
      <menu-fill :target="model.giftBackground" target-key="giftBackground" />
      <menu-background-image class="mt-2" :target="model.giftBackground" target-key="giftBackground" />
    </menu-item>
    <menu-item class="pt-3" label="进度条背景" size-tip="(建议比例4:1)">
      <menu-fill :target="model.progressBackground" target-key="progressBackground" />
      <menu-background-image class="mt-2" :target="model.progressBackground" target-key="progressBackground" />
    </menu-item>
    <menu-item class="pt-3" label="进度条填充" size-tip="(建议比例4:1)">
      <menu-fill :target="model.progressInnerBackground" target-key="progressInnerBackground" />
      <menu-background-image class="mt-2" :target="model.progressInnerBackground" target-key="progressInnerBackground" />
    </menu-item>
<!--    <menu-item class="pt-3" label="进度节点" size-tip="(建议比例4:1)">-->
<!--      <menu-fill :target="model.progressNodeBackground" target-key="progressNodeBackground" />-->
<!--      <menu-background-image class="mt-2" :target="model.progressNodeBackground" target-key="progressNodeBackground" />-->
<!--    </menu-item>-->
    <menu-item class="pt-3" label="奖励背景" size-tip="(建议比例4:1)">
      <menu-fill :target="model.giftItemBackground" target-key="giftItemBackground" />
      <menu-background-image class="mt-2" :target="model.giftItemBackground" target-key="giftItemBackground" />
    </menu-item>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import { ActivityWidgetModel } from '../../model/index'
import MenuItem from "~/components/menu/menuItem";
import MenuSwitch from "~/components/menu/menuSwitch";
import ImSelect from "~/components/common/ImSelect";
import MenuFill from "~/components/menu/menuFill";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage";
import {insertStep} from "~/utils/event";

export default {
  name: "ActivityWidgetEdit",
  components: {
    MenuItem,
    MenuSwitch,
    MenuFill,
    ImSelect,
    MenuBackgroundImage
  },
  props: {
    model: {
      type: Object,
      default() {
        return new ActivityWidgetModel()
      }
    }
  },
  data() {
    return {
      menuList: [],
      typeOptions: [
        // { label: '买X送Y', value: '1' },
        { label: '累充', value: '2' },
      ]
    }
  },

  methods: {
    switchChange(value, item) {
      const layout = cloneDeep(this.model.layout)
      insertStep(this, ['layout'], layout)
      item.value = value
    }
  }
}
</script>

<style lang='less' scoped>
</style>
<style lang="less">
.activity-rich-text {
  position: relative;
  margin-bottom: 16px;
  .ql-editor.ql-blank::before {
    color: rgba(255, 255, 255, 0.3);
    font-style: normal;
  }
  .ql-bubble {
    .ql-tooltip {
      position: absolute;
      top: 38px !important;
      left: 0 !important;
      background: #151724 !important;
      border: 0.5px solid rgba(255, 255, 255, 0.3);
      z-index: 2021;
    }
  }
  .rich-text {
    border-radius: 2px;
    &:hover {
      box-shadow: none;
    }
  }
}
</style>
