<template>
  <client-only>
    <div class="ui-setting-menu" :class="{ 'active': selected }" @click="scrollToView">
      <label v-if="!selected">
        <component :is="model.icon + 'Svg'" v-if="model.icon" class="icon"></component>
      </label>
      <label v-else>
        <component :is="model.icon + 'SelectSvg'" v-if="model.icon" class="icon"></component>
      </label>
      <label class="name ml-10px">
        {{ $t(`model.${model.title}`) }}
      </label>
      <transition name="slide-fade">
        <div v-if="selected && model.draggable" class="handle-box flex items-center justify-center cursor-move move-btn">
          <drag-line-svg />
        </div>
      </transition>
    </div>
  </client-only>
</template>
<script>
import dragLineSvg from '~/assets/svg/icon/drag-line.svg'
import headerSvg from '~/assets/svg/icon/header.svg'
import headerSelectSvg from '~/assets/svg/icon/header-hover.svg'
import codeSvg from '~/assets/svg/icon/code.svg'
import codeSelectSvg from '~/assets/svg/icon/code-hover.svg'
import footerSvg from '~/assets/svg/icon/footer.svg'
import footerSelectSvg from '~/assets/svg/icon/footer-hover.svg'
import storeSvg from '~/assets/svg/icon/store.svg'
import storeSelectSvg from '~/assets/svg/icon/store-hover.svg'
import carouselSvg from '~/assets/svg/icon/carousel.svg'
import carouselSelectSvg from '~/assets/svg/icon/carousel-hover.svg'
export default {
  name: "UiSettingMenu",
  components: {
    dragLineSvg,
    headerSvg,
    footerSvg,
    codeSvg,
    codeSelectSvg,
    headerSelectSvg,
    footerSelectSvg,
    storeSvg,
    carouselSvg,
    storeSelectSvg,
    carouselSelectSvg
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    selected: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      required: true,
      default() {
        return {
          id: '',
          icon: '',
          title: '',
        }
      }
    }
  },
  methods: {
    scrollToView() {
      const item = this.model
      if (item.id) {
        const dom = document.getElementById(item.id)
        const $wrap = document.querySelector('.edit-content__center--wrapper')
        $wrap && $wrap.scrollTo({
          top: dom && dom.offsetTop || 0,
          behavior: 'smooth'
        })
      }
      this.show = false
      this.$emit('selectChangeByIndex', this.index)
    }
  }
}
</script>

<style lang="less" scoped>
.ui-setting-menu {
  display: flex;
  height: 52px;
  line-height: 52px;
  padding-left: 20px;
  position: relative;
  &.active{
    color: @primary-color;
    background: @primary-color-alpha-1;
  }

  .icon {
    //stroke: @text-color-secondary;
  }
  .name {
    flex: 1;
  }
  .handle-box {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 35px;
    background-color: @dark-bg;
  }
}
</style>
