<template>
  <div class="editor-code-menu">
    <p class="mt-16px help-color">{{ $t('menu.codeTip') }}</p>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import { EditorCodeBlockWidgetModel } from '../../model'
import RemoveBtn from "~/components/common/RemoveBtn.vue";
export default {
  name: "EditorCodeBlockWidgetEdit",
  components: {
    RemoveBtn
  },
  props: {
    model: {
      type: Object,
      default() {
        return new EditorCodeBlockWidgetModel()
      }
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
  }
}
</script>

<style lang="less">
.editor-code-menu {
  // padding: 24px 22px;
}
</style>
