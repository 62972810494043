// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".store-rich-text{margin-bottom:16px;position:relative}.store-rich-text .ql-editor.ql-blank:before{color:hsla(0,0%,100%,.3);font-style:normal}.store-rich-text .ql-bubble .ql-tooltip{background:#151724!important;border:.5px solid hsla(0,0%,100%,.3);left:0!important;position:absolute;top:38px!important;z-index:2021}.store-rich-text .rich-text{border-radius:2px}.store-rich-text .rich-text:hover{box-shadow:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
