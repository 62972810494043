<template>
  <div class="carousel-menu-wrap">
    <menu-title :close="close">注册登录</menu-title>
    <menu-item class="menu-item-switch" :label="$t('head.backImg')">
      <div class="custom-back-img">
        <menu-fill :target="model.loginBackground" />
        <menu-background-image class="mt-2" :target="model.loginBackground" eye-key="backgroundImageShow" />
<!--        <upload-block class="upload-block" :bg="model.loginBackground.backgroundImage" :suggestion="{w:32 ,h:32}" @click.native="handleShowBackgroundMenu({w:32 ,h:32})" />-->
      </div>
    </menu-item>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';
import menuTitle from '~/components/menu/menuTitle'
import MenuItem from '~/components/menu/menuItem'
import MenuFill from '~/components/menu/menuFill'
import menuBackgroundImage from "~/components/menu/menuBackgroundImage";
export default {
  name: 'EditMenuCollapse',
  components: {
    MenuFill,
    MenuItem,
    menuTitle,
    menuBackgroundImage
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {

    }
  },
  computed: {

  },

  methods: {
    ...mapMutations({
      'ADD_MENU_KEY': 'menu/ADD_MENU_KEY',
      'REMOVE_MENU_KEY': 'menu/REMOVE_MENU_KEY'
    }),
    close() {
      this.REMOVE_MENU_KEY('collapse')
    },
    handleShowBackgroundMenu(key,model){
      let p = this.$parent
      while ( !p.selectSubmenu || !p){
        p = p.$parent
      }
      p &&  p.selectSubmenu('background', this.model.loginBackground,)
    }
  }
}
</script>

<style lang="less">
.carousel-menu-wrap {
}
</style>
