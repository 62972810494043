<template>
  <div class="im-image">
    <slot v-if="loading" name="placeholder">
      <div class="im-image__placeholder"></div>
    </slot>
    <slot v-else-if="isError" name="error">
      <div class="im-image__error w-full h-full items-center justify-center flex">
        <img src="~/assets/img/pic-404.png" alt="error" />
      </div>
    </slot>
    <img v-else v-bind="$attrs" class="im-image__inner w-full h-full" :src="src" :alt="alt" v-on="$listeners" />
  </div>
</template>

<script>
import throttle from 'lodash.throttle'
import { isHtmlElement, isString } from '~/utils/types'
import { getScrollContainer, on, off, isInContainer } from '~/utils/dom'
export default {
  components: {},
  props: {
    src: {
      type: String,
      default: ''
    },
    scrollContainer: {
      type: [Object, String],
      default() {
        return {}
      }
    },
    alt: {
      type: String,
      default: ''
    },
    isLazy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      isError: false,
      show: false,
      throttleLazyLoadImage: '',
      scrollWrapper: {}
    }
  },
  watch: {
    src() {
      this.show && this.loadImage()
    },
    show(val) {
      val && this.loadImage()
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.isLazy && this.removeLazyLoadListener()
  },
  methods: {
    init() {
      if (this.isLazy) {
        this.addLazyLoadListener()
      } else {
        this.loadImage()
      }
    },
    removeLazyLoadListener() {
      off(this.scrollWrapper, 'scroll', this.throttleLazyLoadImage)
      this.throttleLazyLoadImage = null
    },
    loadImage() {
      const image = new Image()
      image.onload = e => this.handleLoad(e, image)
      image.onerror = this.handleError.bind(this)
      Object.keys(this.$attrs).forEach(key => {
        const value = this.$attrs[key]
        image.setAttribute(key, value)
      })
      image.src = this.src
    },
    handleLoad(e, img) {
      this.loading = false
      this.isError = false
    },
    handleError() {
      this.loading = false
      this.isError = true
    },
    handleLazyLoad() {
      if (isInContainer(this.$el, this.scrollWrapper)) {
        this.show = true
        this.removeLazyLoadListener()
      }
    },
    addLazyLoadListener() {
      const { scrollContainer } = this
      let $scroll
      if (isHtmlElement(scrollContainer)) {
        $scroll = scrollContainer
      } else if (scrollContainer && isString(scrollContainer)) {
        $scroll = document.querySelector(scrollContainer)
      } else {
        $scroll = getScrollContainer(this.$el)
      }

      if ($scroll) {
        this.throttleLazyLoadImage = throttle(this.handleLazyLoad, 200)
        this.scrollWrapper = $scroll
        this.throttleLazyLoadImage()
        on(this.scrollWrapper, 'scroll', this.throttleLazyLoadImage)
      }
    }
  }
}
</script>

<style lang="less">
.im-image {
  &__inner {

  }
}
</style>
