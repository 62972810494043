// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".search-input-popper{background-color:#3b3a46;border-color:#3b3a46;padding:0 8px}.search-input-popper div.popper__arrow{display:none}.search-input-popper li{color:#fff}.search-input-popper li.hover{background-color:#3d46f5!important;color:#fff}.search-input-popper li:hover{background-color:#3d46f5;color:#fff}.search-input-popper li.is-disabled:hover{background-color:transparent}.search-input .el-input__inner{background-color:hsla(0,0%,100%,.05);border:1px solid transparent;color:hsla(0,0%,100%,.9)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
