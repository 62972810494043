// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nav-edit-popper{background-color:#1e1e1e;border:0;border-radius:2px;box-shadow:0 2px 10px 0 rgba(0,0,0,.36);margin:0;padding:0}.nav-edit-popper .popover-content__header{border-bottom:1px solid hsla(0,0%,56%,.4);height:40px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
