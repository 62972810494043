<template>
  <transition name="el-fade-in">
    <div
      v-if="visible"
      :style="{
        left: styleLeft,
        bottom: styleBottom,
      }"
      class="scroll-show"
      @click.stop="handleClick"
    >
      <slot> </slot>
    </div>
  </transition>
</template>

<script>
import throttle from 'lodash.throttle'

export default {
  name: 'ScrollShow',
  props: {
    visibilityHeight: {
      type: Number,
      default: -1,
    },
    target: [String],
    left: {
      type: Number,
      default: 40,
    },
    bottom: {
      type: Number,
      default: 40,
    },
  },
  data() {
    return {
      el: null,
      container: null,
      visible: false,
    }
  },
  computed: {
    styleBottom() {
      return `${this.bottom}px`
    },
    styleLeft() {
      return `${this.left}px`
    },
  },
  mounted() {
    this.init()
    this.throttledScrollHandler = throttle(this.onScroll, 300)
    this.container.addEventListener('scroll', this.throttledScrollHandler)
    this.onScroll()
  },
  beforeDestroy() {
    this.container.removeEventListener('scroll', this.throttledScrollHandler)
  },
  methods: {
    init() {
      this.container = document
      this.el = document.documentElement
      if (this.target) {
        this.el = document.querySelector(this.target)
        if (!this.el) {
          throw new Error(`target is not existed: ${this.target}`)
        }
        this.container = this.el
      }
    },
    onScroll() {
      const scrollTop = this.el.scrollTop
      this.visible = scrollTop >= this.visibilityHeight
    },
    handleClick(e) {
      this.$emit('click', e)
    },
  },
}
</script>

<style lang="less" scoped>
.scroll-show {
  position: fixed;
  width: 140px;
  height: 140px;
  overflow: visible;
  border-radius: 50%;
  // color: @fill-color-3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  // box-shadow: 0 0 6px rgba(0,0,0, .12);
  cursor: pointer;
  z-index: 5;

  // &:hover {
  //   background-color:#fff;
  // }
}

.@{mobile-cls}{
  .scroll-show {
    width: 70px;
    height: 70px;
  }
}

</style>