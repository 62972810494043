<template>
  <div class="edit-background-menu-wrap">
    <menu-title v-if="showTitle" :close="close">{{$t('head.backImg')}}</menu-title>
    <menu-item  v-if="colorKey" :label="$t('edit.peiSe')">
      <div class="flex color-list">
        <bg-style v-for="(item, index) in colorList" :key="index" class="cursor-pointer color-item"
                  :bg="{  ...target,  backgroundColor: item}"
        :class="{active: item === target[colorKey]}" @click.native="colorChange(item)">
        </bg-style>
        <div class="relative flex items-center justify-center cursor-pointer color-item set" @click.stop.prevent="handleShowColor">
          <set-svg class="fill-white" />
          <ColorPicker ref="color" :value="target[colorKey] || model[colorKey]" :index="1.6" show-alpha @change="colorChange"   @active-change="colorChange"></ColorPicker>
        </div>
      </div>
    </menu-item>
   <!-- 设置图像包含模式-->
    <menu-item  v-if="imageKey && imageSizeKey" :label="$t('edit.imageColor')">
      <button v-for="bgSize in bgSizeList" :key="bgSize" class="bg-size-item" :class="{'bg-size-item-active': target[imageSizeKey] === bgSize}"
              @click="changeBackGroundSizeMethod(bgSize)">  {{$t('edit.' + bgSize)}} </button>
    </menu-item>

    <menu-item v-if="imageKey"  :label="$t('model.assets')">
      <assets-material in-widget :value="target[imageKey] || model[imageKey]" :suggestion="suggestion" @change="imgChange"></assets-material>
    </menu-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import tinycolor from 'tinycolor2'
import { BackgroundSizeList } from '~/enums/DomEnum'
import menuTitle from "~/components/menu/menuTitle";
import menuItem from "~/components/menu/menuItem";
import AssetsMaterial from '~/site/components/assetsMaterial';
import ColorPicker from '~/components/colorPickers/index'
import setSvg from '~/assets/svg/icon/set.svg';
export default {
  name: 'EditMenuBackground',
  components: {
    menuTitle,
    menuItem,
    setSvg,
    ColorPicker,
    AssetsMaterial,
    // uploadBlock
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    showTitle:{
      type: Boolean,
      default: true,
    },
    target: [Object, String],
    colorKey: {
      type: String,
      default: 'backgroundColor',
    },
    imageSizeKey: {
      type: String,
      default: 'backgroundSize',
    },
    imageKey:{
      type: String,
      default: 'backgroundImage',
    },
    suggestion:{
      type: Object,
      default(){
        return null
      },
    }
  },
  data() {
    return {
      bgSizeList: BackgroundSizeList
    }
  },
  computed: {
    ...mapGetters(['recentColors']),
    colorList() {
      let val = this.target[this.colorKey] || this.model[this.colorKey] || '#FFF'
      if (typeof val !== 'string') {
        val = '#FFF'
      }
      const color = tinycolor(val)
      return [0, 0.4, 0.7, 1].map(item => {
        return color.setAlpha(item).toRgbString()
      })
    }
  },
  methods: {
    close() {
      this.SiteMenu.close()
    },
    handleShowColor() {
      this.$refs.color.handleShowColor()
    },
    changeBackGroundSizeMethod(value){
      if(this.target && this.target.setBackgroundSize){
        this.target.setBackgroundSize(value, this.imageSizeKey)
      }
      else if(this.model.setBackgroundSize){
        this.model.setBackgroundSize(value, this.imageSizeKey)
      }
      else{
        this.$set(this.target, this.imageSizeKey, value)
      }
    },
    colorChange (value) {
      if(this.target && this.target.setBackgroundColor){
        this.target.setBackgroundColor(value, this.colorKey)
      }else{
        this.$set(this.target, this.colorKey, value)
      }
    },
    imgChange (url) {
      if (this.target && this.target.setBackgroundImage) {
        this.target.setBackgroundImage(url, this.imageKey)
      } else if (this.model.setBackgroundImage) {
        this.model.setBackgroundImage(url, this.imageKey);
      } else {
        this.$set(this.target, this.imageKey, url)
      }
    }
  }
}
</script>

<style lang="less">
.edit-background-menu-wrap {
  .color-list {
    gap: 8px;
    .color-item {
      width: 33px;
      height: 33px;
      border-radius: 2px;
      border: 0.5px solid rgba(255, 255, 255, 0.3);
      position: relative;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 50%;
      .color-bg{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
      }
      &.active{
        border: 1px solid #5C6FFE;
      }
      &:hover {
        border-color: @primary-color;
      }
      &.set {
        background-color: #333;
      }
      .color-picker {
        pointer-events: none;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .bg-size-item{
    display: block;
    width: 100%;
    height: 32px;
    line-height: 32px;
    color: @text-color-secondary;
    background-color:@widget-menu-bg;
    text-align: center;
    border-radius: 2px;
  }
  .bg-size-item-active{
    color: @text-color;
    background-color: @primary-color-alpha-1;
  }
}
</style>
