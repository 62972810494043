// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".head-widget-edit-wrap .help-popper.is-dark{background-color:#362c7f}.head-widget-edit-wrap .help-popper.is-dark .popper__arrow,.head-widget-edit-wrap .help-popper.is-dark .popper__arrow:after{border-bottom-color:#362c7f!important;border-top-color:#362c7f!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
