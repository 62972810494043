<template>
  <div>
    <ColorPicker
      v-if="show" ref="color"
      class="visibility-hidden_picker"
      :value="value"
      v-bind="$props"
      :style="getStyle"
      :preset-colors="recentColors"
      @input="changeColor"
    ></ColorPicker>
  </div>
</template>

<script>
import { Sketch } from 'vue-color'
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import { on, off } from '~/utils/dom'

export default {
  name: "SiteBuilderColorPicker",
  components: {
    ColorPicker: Sketch
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: [String],
      default: '',
    },
    showAlpha: Boolean,
    disabled: Boolean,
    index: {
      type: Number,
      default: 0
    },
    /** Custom class name for ColorPicker's dropdown */
    colorFormat: {
      type: String,
      default: 'rgb'
    },
    gap: {
      type: Number,
      default: 18
    }
  },
  data() {
    return {
      show: false,
      debounceSetRecentColor: ''
    }
  },
  computed: {
    ...mapGetters(['recentColors']),
    getStyle() {
      const { index, gap } = this
      return {
        left: -index * 100 - gap + 'px'
      }
    }
  },
  mounted() {
    this.debounceSetRecentColor = debounce(this.setRecentColor, 1000)
    on(document.body, 'click', this.clickOut)
  },
  beforeDestroy() {
    off(document.body, 'click', this.clickOut)
  },
  methods: {
    setRecentColor(rgba) {
      this.$store.commit('theme/SET_RECENT_COLORS', rgba)
    },
    clickOut() {
      this.show = false
    },
    changeColor(color) {
      let rgba = color
      if (color.rgba) {
        const {r, g, b, a} = color.rgba
        rgba = `rgba(${r},${g},${b},${a})`
      }
      this.debounceSetRecentColor(rgba)
      this.$emit('change', rgba)
    },
    handleShowColor() {
      this.show = true
    },
  }
}
</script>

<style lang="less" scoped>
.site-color-picker {
  position: relative;
  width: 100%;
  height: 33px;
  border-radius: 2px;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 16px;
}

.visibility-hidden_picker {
  pointer-events: auto;
  //visibility: hidden;
  position: absolute;
  top: 0;
  left: -18px;
  z-index: 2000;

  /deep/ .vc-sketch-field {
    color: @heading-color;
  }
}
</style>
