// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".upload-block-wrap{background-color:#273261;padding:16px 8px 12px;position:relative}.upload-block-wrap .upload-block-wrap-bg{background-repeat:no-repeat;background-size:100% 100%;bottom:0;filter:opacity(.6);left:0;opacity:.6;position:absolute;right:0;top:0}.upload-block-wrap .upload-svg{fill:#3d46f5}.upload-block-wrap.ghost{background-color:transparent;border:1px dashed hsla(0,0%,56%,.4);justify-content:flex-start;padding:0 18px}.upload-block-wrap.ghost .name{color:hsla(0,0%,100%,.6);margin-left:8px}.upload-block-wrap .suggestion{color:rgba(92,111,254,.6);margin-top:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
