<template>
  <div class="remove-btn text-12" @click="handleShowDeleteDialog">
    <icon-delete />
    <span class="ml-2">{{ text }}</span>
  </div>
</template>

<script>
import iconDelete from '~/assets/svg/icon/iconDelete.svg'
export default {
  name: 'RemoveBtn',
  components: {
    iconDelete
  },
  props: {
    clickFun: {
      type: [Function, String],
      default: ''
    },
    text: {
      type: String,
      default: '删除区块'
    }
  },
  data() {
    return {
    }
  },
  methods: {
    handleShowDeleteDialog() {
      if (this.clickFun) {
        this.clickFun()
      } else {
        this.SiteMenu.openDeleteBlockDialog()
      }
    }
  }
}
</script>

<style lang="less">
.remove-btn {
  border-radius: @border-radius-base;
  border: 1px dashed rgba(255, 96, 85, 0.4);
  height: @base-height;
  display: flex;
  align-items: center;
  justify-content: center;
  color: @danger-color;
  cursor: pointer;
  &:hover {
    border: 1px dashed @danger-color;
  }
}
</style>
