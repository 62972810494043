<template>
  <button class="site-button"  :class="{
    'site-button-large': size ==='large',
    'site-button-text': type === 'text',
    'site-button-border': !!border,
    'site-button-normal': !!normal,

  }" :type="htmlType"
     :disabled="disabled || loading"
     v-on="$listeners"
   >
    <im-icon v-if="loading" class="icon-loading" icon="icon-loading"></im-icon>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "SiteButton",
  props:{
    normal:{
      type: Boolean,
      default: false,
    },
    size:{
      type: String,
      default: ''
    },
    htmlType:{
      type: String,
      default: 'button'
    },
    loading:{
      type: Boolean,
      default: false
    },
    type:{
      type: String,
      default: ''
    },
    border:{
       type: Boolean,
       default: false
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },

}
</script>

<style lang="less" scoped>


 .site-button{
   position: relative;
   background-color: var(--button-background-color);
   background-image: var(--button-background-image);
   opacity: var(--button-background-opacity);
   color: var(--button-text-color);
   display: inline-block;
   text-align: center;
   line-height: 36px;
   padding: 0px 7px;
   font-size: 14px;
   box-sizing: border-box;
   border-radius: var(--button-border-radius-px);
   &:hover{
     color: var(--text-color);
   }
   &:disabled{
     color: #C9C9D4;
     background: #E5E6EB;
     cursor: not-allowed;
   }
 }
 .site-button-large{
   display: block;
   width: 100%;
 }
 .site-button-text{
   padding: 0px;
   color: var(--site-button-text);
   background: none;
   &:hover{
     color: var(--site-button-text-hover);
   }
   &:disabled{
     color: @disabled-color;
     cursor: not-allowed;
   }
 }
 .site-button-normal{
   color: var(--site-button-normal);
   &:hover{
     color: @text-color-secondary ;
   }
   &:disabled{
     color: @disabled-color;
   }
 }

 .site-button-border{
   border: 1px solid var(--text-color);
 }
 @keyframes rotating {
   0% {
     transform: rotate(0deg);
   }

   to {
     transform: rotate(1turn);
   }
 }


 .icon-loading{
   animation: rotating  2s linear infinite;
 }
</style>
<style scoped lang="css">

</style>
