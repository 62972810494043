// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".model-wrap .site-input__inner{background-color:#fff!important;border-radius:4px;box-sizing:border-box;color:#18171c!important;padding:10px 16px}.model-wrap .site-input__inner::-moz-placeholder{color:#c9c9d4;font-size:14px}.model-wrap .site-input__inner::placeholder{color:#c9c9d4;font-size:14px}.model-wrap .site-input__inner:focus{outline:#5a47ee}.model-wrap .site-input__inner.disabled{background:#f7f8fa;color:#c9c9d4}.model-wrap .site-form-item{margin-bottom:32px}.model-wrap .site-form-item:last-child{margin-bottom:0}.model-wrap .site-button{font-size:16px!important}.login-widget-wrap_desk .site-input__inner{height:46px;line-height:46px}.login-widget-wrap_desk .back_btn,.login-widget-wrap_desk .forget_btn{font-size:14px!important}.login-widget-wrap_mobile .site-input__inner,.login-widget-wrap_mobile_site .site-input__inner{height:48px;line-height:48px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
