// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".column-menu__item{align-items:center;border-radius:2px;color:#fff;cursor:pointer;display:flex;font-size:12px;height:36px;margin-bottom:4px;padding-left:12px}.column-menu__item:hover{background-color:hsla(0,0%,100%,.08)}.column-menu__item.active{background-color:#273261}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
