<template>
  <div>
    <div class="upload-trigger">
      <div class="upload-text">
        <im-icon class="icon-upload"  icon="icon-upload"></im-icon>
        <label>{{ text }}</label>
      </div>
    </div>
    <div v-if="tip" class="tip">
      {{ tip }}
      {{suggestion && suggestion.w && suggestion.h? ','+$t('upload.suggestion',suggestion): ''}}
      {{suggestion && suggestion.extra}}
    </div>
   </div>
</template>

<script>
import ImIcon from "~/components/common/ImIcon";
export default {
  name: "UploadTrigger",
  components: {ImIcon},
  props: {
    text: {
      type: String,
      default: 'Upload Picture'
    },
    tip:{
      type: String,
      default: 'The size of the uploaded picture shall not exceed 2MB!'
    },
    suggestion:{
      type: Object,
      default(){
        return null
      }
    }
  }
}
</script>

<style lang="less" scoped>
.upload-trigger {
  border: 1px @line-color-2 dashed;
  border-radius: 4px;
  color:  @primary-color;
  width: 100%;
  padding: 8px 16px;
  box-sizing: border-box;


  &:hover {
    border-color: @primary-color;
    color: @primary-color;
    background: #1A1837;
  }
  .upload-text{
    display: flex;
    align-items: center;
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
  }
  .icon-upload {
    font-size: 20px;
  }

  label {
    margin-left: 8px;
  }

}
 .tip{
    margin-top: 8px;
    font-size: 12px;
    color: @text-light-color
  }
</style>
