// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".register-form .rules-box{color:#8e8e8e}.register-form .rules-box .item{align-items:center;color:#8e8e8e;display:flex}.register-form .rules-box .item.is-success{color:#009a29}.register-form .rules-box .item.is-error{color:#f16b67}.register-form .rules-box .item .circle{background-color:currentColor;border-radius:100%;height:4px;margin-right:8px;width:4px}.register-form .rules-box+.site-form-item__error{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
