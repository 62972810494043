<template>
  <div class="edit-nav-wrap">
    <menu-title :close="close">编辑菜单项</menu-title>
    <menu-item label="名称">
      <base-input v-model="target.label" :value="target.label" size="small" />
    </menu-item>
    <menu-item label="URL">
      <search-input
        v-model="target.url"
        class="w-full"
        :suggest-list="SitePage.list"
        size="small"
        @select="handleSelectUrl"
      />
    </menu-item>
    <menu-item label="模块">
      <im-select
        v-model="target.model"
        class="w-full"
        :options="modelOptions"
        size="small"
        clearable
        placeholder="请选择模块"
        @change="handleChangeModel"
      />
    </menu-item>
  </div>
</template>

<script>
import menuTitle from "~/components/menu/menuTitle";
import menuItem from "~/components/menu/menuItem";
import BaseInput from "~/components/base/baseInput"
import searchInput from "~/components/im30/searchInput";
import ImSelect from "~/components/common/ImSelect";

export default {
  name: 'EditMenuBackground',
  components: {
    menuTitle,
    menuItem,
    BaseInput,
    searchInput,
    ImSelect
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    target: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      name: '',
      url: '',
      modelOptions: []
    }
  },
  created() {
    const nameMap = {
      CarouselWidgetModel: '轮播图',
      StoreWidgetModel: "商城",
      EditorCodeBlockWidgetModel: "自定义代码",
      ImageTextWidgetModel: '图文',
      CollapseWidgetModel: "折叠内容",
      ActivityWidgetModel: "累充"
    }
    const { current, list } = this.SitePage
    const currentPage = list.find(i => i.id === current)
    const conf = currentPage && currentPage.conf || {}
    const web = conf && conf.web || {}
    const site = web && web.site || {}
    const widgetList = site && site.widgetList || []
    const options = widgetList.filter(i => i.visible && i.title !== "header" && i.title !== "footer").map((i, d) => {
      const name = nameMap[i.name]
      return { label: `${name}-${d + 1}`, value: i.id }
    })
    this.modelOptions = options || []
  },
  methods: {
    close() {
      this.SiteMenu.close('navediter')
    },
    handleSelectUrl() {
      this.target.model = ''
    },
    handleChangeModel() {
      this.target.url = ''
    }
  }
}
</script>
<style lang="less" scoped>
</style>
