<template>
  <div>
    <login-widget-modal :device="device" :editing="editing" :model="site && site.loginSetting" />
    <support-setting-widget-modal :device="device" :editing="editing" :model="site && site.supportSetting" />
    <dialog-widget-modal v-if="supportDialog" :device="device" :editing="editing" :model="site && site.dialogSetting" />
  </div>
</template>

<script>
import LoginWidgetModal from '../widgets/loginSetting/index'
import SupportSettingWidgetModal from '../widgets/supportSetting/index'
import DialogWidgetModal from "~/site/widgets/dialogWidget/index.vue";
import {PageEnum} from "~/enums/pageEnum";

export default {
  name: "SiteModals",
  components: {
    LoginWidgetModal,
    SupportSettingWidgetModal,
    DialogWidgetModal
  },
  props: {
    site: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      default: false
    },
    device: {
      type: String,
      default: 'desktop'
    }
  },
  data() {
    return {}
  },
  computed: {
    supportDialog() {
      return !this.$route.fullPath.includes(PageEnum.SHOP_CART)
    }
  },
  watch: {},

  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>

<style lang="less" scoped>

</style>
