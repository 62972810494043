<template>
 <div>
 </div>
</template>

<script>
// 属性编辑
export default {
  name: "SeoSetting",
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  mounted() {
    //
  }
}
</script>

<style lang="less" scoped>

</style>
