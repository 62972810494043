<template>
 <div>

 </div>
</template>

<script>
// 属性编辑
export default {
  name: "AllowSetting",
}
</script>

<style lang="less" scoped>

</style>
