<template>
  <div class="im-tip flex w-full items-center">
    <icon-warn />
    <span class="text-white text-12 ml-2">{{ text }}</span>
  </div>
</template>

<script>
import iconWarn from '@/assets/svg/icon/iconWarn.svg'
export default {
  components: {
    iconWarn
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="less">
.im-tip {
  background-color: @warning-color;
  border-radius: @border-radius-base;
  padding: 4px 10px;
}
</style>
