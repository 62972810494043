// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".create-page-dialog .el-form-item,.create-page-dialog .el-form-item__label{color:#0a0a0a}.create-page-dialog .el-form-item .el-input__inner,.create-page-dialog .el-form-item .el-textarea__inner{border-color:#e8e8ea;color:#0a0a0a}.create-page-dialog .el-form-item .el-input__inner:focus,.create-page-dialog .el-form-item .el-textarea__inner:focus{border-color:#3f54f2;color:#0a0a0a}.create-page-dialog .el-form-item.link .el-input-group__prepend{background:#ebecf0}.create-page-dialog .el-form-item.link .el-input__inner{padding-left:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
