<template>
<!--  eslint-disable -->
  <Dialog :visible="value"
          :class="prefixCls"
          :custom-class="prefixCls"
          v-bind="$attrs"
          top="16vh"
          v-on="$listeners"
          :show-close="false"
          @open="onOpened"
  >
<!--    <slot name="title">-->
      <div class="title text-18" slot="title">
        <div class="flex items-center">
          <icon-warn v-if="type === 'warning'" />
          <span class="ml-1">{{title}}</span>
        </div>
        <div class="close cursor-pointer" @click.stop="closeMethod">
          <i class="el-icon-close text-20 font-bold text-[#A7A7A7]" />
        </div>
      </div>
    <slot />
  </Dialog>
</template>

<script>
import {Dialog} from 'element-ui'
import iconWarn from '@/assets/svg/icon/iconWarning.svg'
import { useGlobSetting } from '~/hooks/useGlobSetting'

export default {
  name: "BaseDialog",
  components: {
    Dialog,
    iconWarn
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false,
    },
    title:{
      type: String,
      default: '',
    },
    offset: {
      type: Array,
      default: () => [0, 0, 0, 0]
    }
  },
  data() {
    const {prefixCls} = useGlobSetting()
    return {
      prefixCls: prefixCls + '-dialog'
    }
  },
  methods:{
    closeMethod(){
      this.$emit('change', false)
    },
    onOpened() {
      this.$nextTick(() => {
        if(!this.$el) {
        return;
      }
      const top = this.offset[0] || 0;
      const right = this.offset[1] || 0;
      const bottom = this.offset[2] || 0;
      const left = this.offset[3] || 0;
      const parentDom = this.$el.parentNode;
      if(parentDom) {
        const modelDom = parentDom.lastChild;
        if(modelDom) {
          modelDom.style.top = top + 'px';
          modelDom.style.left = left + 'px';
          modelDom.style.right = right + 'px';
          modelDom.style.bottom = bottom + 'px';
        }
      }
      if(this.$el) {
        const dom = this.$el
        dom.style.top = top + 'px';
        dom.style.left = left + 'px';
        dom.style.right = right + 'px';
        dom.style.bottom = bottom + 'px';
      }
      })

    }
  }
}
</script>

<style lang="less" scoped>
@prefix-cls: ~'@{namespace}-dialog';


.@{prefix-cls} {

  /deep/ .el-dialog {
    background-color: white;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-height: 230px;
      border-radius: 2px;
      opacity: 0.86;
      background: linear-gradient(180deg, rgba(255,255,255,0.00) 0%, #FFFFFF 74%), linear-gradient(90deg, #DDEFFF 0%, #E7E1FF 51%, #D8E1FF 99%);
    }
  }
  /deep/ .el-dialog__header {
    position: relative;
    padding: 24px 16px 0 16px;
    z-index: 10;

    .title{
      position: relative;
      color: #0A0A0A;
      .close{
        position: absolute;
        display: inline-block;
        right: 0;
        bottom: 0;
      }
    }

  }
  /deep/.el-dialog__body{
    padding: 0;
    position: relative;
    z-index: 10;
  }
  .dialog-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 460px;
    max-height: 230px;
    border-radius: 2px;
    opacity: 0.86;
    background: linear-gradient(180deg, rgba(255,255,255,0.00) 0%, #FFFFFF 74%), linear-gradient(90deg, #DDEFFF 0%, #E7E1FF 51%, #D8E1FF 99%);
  }

}
</style>
