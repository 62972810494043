<template>
  <div class="column-menu">
    <template v-if="model.__children">
      <div v-for="(item, index) in model.__children" :key="index" class="column-menu__item" :class="{ 'active': index === SiteMenu.columnChildIndex }" @click="handleItemClick(item, index)">
        {{ item.name }}
      </div>
    </template>
    <template v-else>
      <div v-for="(item, index) in model" :key="index" class="column-menu__item" :class="{ 'active': index === SiteMenu.columnChildIndex }" @click="handleItemClick(item, index)">
        {{ item.getMenuInfo().name }}
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'ColumnMenu',
  props: {
    model: [Object, Array]
  },
  data() {
    return {
      //
    }
  },
  created() {
    //
  },
  methods: {
    handleItemClick(item, index) {
      this.SiteMenu.close()
      this.$store.commit('user/CLEAR_SITE_LOGIN_USER_MODAL')
      this.SiteMenu.setColumnChildIndex(index)
      const model = Object.hasOwn(this.model, '__children') ? this.model : item
      this.$emit('item-click', model, index)
    }
  }
}
</script>

<style lang="less">
.column-menu {
  &__item {
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: white;
    padding-left: 12px;
    border-radius: @border-radius-base;
    cursor: pointer;
    margin-bottom: 4px;
    &:hover {
      background-color: @fill-color-3;
    }
    &.active {
      background-color: @primary-color-light;
    }
  }
}
</style>
