// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".assets-library__list{flex-wrap:wrap;gap:8px}.assets-library__upload{align-items:center;border:1px dashed #fff;border-radius:2px;color:#fff;display:flex;flex-direction:column;height:60px;justify-content:center;width:60px}.assets-library__upload:hover{border-color:#3f54f2;color:#3f54f2}.assets-library__delete{align-items:center;background-color:hsla(0,0%,100%,.08);border-radius:2px;cursor:pointer;display:flex;flex-shrink:0;height:60px;justify-content:center;width:60px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
