// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".collapse_content_desk .site-collapse-item__header{border-radius:1px;min-height:74px;padding:16px 36px}.collapse_content_desk .site-collapse-item__header .collapse-widget-item__title{font-size:20px;line-height:30px}.collapse_content_desk .site-collapse-item__header .site-collapse-item__arrow{font-size:24px;margin-left:16px}.collapse_content_desk .site-collapse-item__wrap{padding:16px 36px}.collapse_content_desk .site-collapse-item__wrap .site-collapse-item__content{padding-bottom:0}.collapse_content_desk .collapse-widget-item_nobg .site-collapse-item__wrap{padding:0 36px}.collapse_content_mobile .site-collapse-item__header{border-radius:1px;min-height:40px;padding:9px 16px}.collapse_content_mobile .site-collapse-item__header .collapse-widget-item__title{font-size:14px;line-height:22px}.collapse_content_mobile .site-collapse-item__header .site-collapse-item__arrow{font-size:16px;margin-left:12px}.collapse_content_mobile .site-collapse-item__wrap{padding:12px 30px}.collapse_content_mobile .site-collapse-item__wrap .site-collapse-item__content{padding-bottom:0}.collapse_content_mobile .collapse-widget-item_nobg .site-collapse-item__wrap{padding:0 30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
