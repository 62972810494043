<template>
  <div class="color-transform">
    <div class="color-transform__content flex items-center px-3">
      <div v-if="type === 'rgba'" class="rgba-bar w-4/5">
        <div v-for="(item, index) in rgba" :key="index" class="item">{{ item }}</div>
      </div>
      <div v-if="type === 'hsla'" class="hsla-bar w-4/5">
        <div v-for="(item, index) in hsla" :key="index" class="item">{{ item }}</div>
      </div>
      <div v-if="type === 'hex'" class="hsla-bar w-4/5">
        <div class="item pl-2 text-left">{{ hex }}</div>
      </div>
      <dropdown class="w-1/5">
        <div class="drop-btn">
          <i class="el-icon-arrow-down text-white font-bold" />
        </div>
        <dropdown-menu slot="dropdown" class="color-transform__popper">
          <dropdown-item v-for="item in typeOptions" :key="item.value" @click.native="handleChangeType(item)">
            <i class="el-icon-check font-bold" :class="{ 'visible': item.value === type }" />
            <span>{{ item.label }}</span>
          </dropdown-item>
        </dropdown-menu>
      </dropdown>
    </div>
    <div v-if="type === 'rgba'" class="color-transform__tip flex items-center px-3">
      <div class="w-1/5 item">R</div>
      <div class="w-1/5 item">G</div>
      <div class="w-1/5 item">B</div>
      <div class="w-1/5 item">A</div>
      <div class="w-1/5 item"> </div>
    </div>
    <div v-if="type === 'hsla'" class="color-transform__tip flex items-center px-3">
      <div class="w-1/5 item">H</div>
      <div class="w-1/5 item">S</div>
      <div class="w-1/5 item">L</div>
      <div class="w-1/5 item">A</div>
      <div class="w-1/5 item"> </div>
    </div>
    <div v-if="type === 'hex'" class="color-transform__tip flex items-center px-3">
      <div class="item pl-2">HEX</div>
    </div>
  </div>
</template>

<script>
import tinycolor from 'tinycolor2'
import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui'
export default {
  components: {
    Dropdown,
    DropdownMenu,
    DropdownItem
  },
  props: {
    value: [String, Array]
  },
  data() {
    return {
      rgba: [],
      hsla: [],
      hex: '',
      type: 'rgba',
      typeOptions: [
        {
          label: 'RGBA',
          value: 'rgba'
        },
        {
          label: 'HSLA',
          value: 'hsla'
        },
        {
          label: 'HEX',
          value: 'hex'
        }
      ]
    }
  },
  watch: {
    value() {
      this.getValue()
    }
  },
  methods: {
    getValue() {
      const rgba = tinycolor(this.value)
      this.rgba = [rgba._r, rgba._g, rgba._b, rgba._a]
      const hslaString = rgba.toHslString()
      const hsla = hslaString.substring(4, hslaString.length - 1).split(',')
      this.hsla = [hsla[0].includes('(') ? hsla[0].substring(1, hsla[0].length) : hsla[0], hsla[1], hsla[2], (hsla[3] || 1)]
      this.hex = rgba.toHex8String()
    },
    handleChangeType(item) {
      this.type = item.value
    }
  }
}
</script>

<style lang="less">
.color-transform {
  margin-bottom: 12px;
  &__popper {
    background-color: @select-bg;
    border: 0;
    .popper__arrow {
      display: none;
    }
    .el-icon-check {
      visibility: hidden;
    }
    .el-icon-check.visible {
      visibility: visible;
    }
    .el-dropdown-menu__item {
      color: white;
      font-size: 12px;
      &:hover {
        background-color: @primary-1;
        color: white;
      }
    }
  }
  &__content {
    width: 100%;
  }
  &__tip {
    .item {
      text-align: center;
      font-size: 12px;
      color: @text-3;
    }
  }
  .rgba-bar,.hsla-bar, .hex-bar {
    display: flex;
    height: @base-height;
    background-color: @fill-color-3;
    border-radius: 2px 0 0 2px;
    .item {
      flex: 1;
      height: 100%;
      text-align: center;
      line-height: @base-height;
      color: white;
      font-size: 12px;
    }
  }
  .drop-btn {
    height: @base-height;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @fill-color-3;
    color: white;
    border-radius: 0 2px 2px 0;
  }
  .el-input__suffix-inner {
  }
  .el-input__inner {
    height: @base-height;
    line-height: @base-height;
    background-color: @fill-color-3;
    &:hover {
      border-color: transparent;
    }
  }
}
</style>
