<template>
  <Popover v-model="visible" trigger="click" :offset="-4" placement="top" popper-class="add-block-popper">
    <div class="w-full">
      <div class="flex items-center justify-center w-full text-center text-white title text-12">
        <span>添加区块</span>
        <div class="circle-close ml-2 w-[12px] h-[12px] rounded-full bg-white flex items-center justify-center cursor-pointer" @click="handleCancel">
          <im-icon class="text-primary" icon="icon-clear" />
        </div>
      </div>
      <div v-if="model.getCanAddWidget" class="list flex items-center justify-center flex-wrap gap-[24px]">
        <div v-for="(item, i) in model.getCanAddWidget()" :key="i" class="flex flex-col items-center justify-center mt-4 cursor-pointer item" @click="addWidgetByIndex(item)">
          <div class="item-header w-[40px] h-[40px] rounded-full bg-textColor-3 flex items-center justify-center">
            <im-icon class="text-white" :icon="item.icon" />
          </div>
          <div class="mt-2 text-textColor-6">{{ $t(`model.${item.title}`) }}</div>
        </div>
      </div>
    </div>
    <div slot="reference" class="add-block-opener ">
      <button class="p-0 add-block-opener__btn">
        <add-svg class="transform scale-150" />
        <!-- <span class="ml-8px">{{ $t('添加区块') }}</span> -->
      </button>
    </div>
  </Popover>
</template>

<script>
import { Popover } from 'element-ui'
import cloneDeep from "lodash.clonedeep";
import addSvg from '~/assets/svg/icon/add-green.svg?inline'
import ImIcon from "~/components/common/ImIcon";
import {insertReduceStep} from "~/utils/event";
export default {
  name: 'AddBlockMobile',
  components: {
    ImIcon,
    Popover,
    addSvg
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    addWidgetByIndex(item) {
      const index = this.index + 1
      const model = this.model.addWidgetByIndex(item.model, index)
      insertReduceStep(this, { element: cloneDeep(model), index })
    },
    handleCancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="less">
.add-block-popper {
  width: 376px;
  background-color: @primary-3;
  border: 0;
  outline: none;
  .popper__arrow {
    border-top-color: @primary-3 !important;
    border-bottom-color: @primary-3 !important;
    &::after {
      border-top-color: @primary-3 !important;
      border-bottom-color: @primary-3 !important;
    }
  }
}
</style>
