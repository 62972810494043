<template>
  <Popover v-model="visible"  popper-class="qrcode-view-popper" width="220px" :visible-arrow="false" trigger="hover" @show="onShow">
    <div class="qrcode-area text-textColor-7 flex items-center justify-center flex-col">
      <img id="qrcode" class="max-w-[136px]" alt="qrcode" :src="dataUrl" />
      <span class="mt-4 text-12">扫描二维码查看移动端效果</span>
      <div class="px-2 w-full py-2 bg-fillColor-3 text-textColor-7 flex rounded-sm mt-2 items-center hover:text-white">
        <span class="overflow-hidden text-ellipsis whitespace-nowrap text-12">链接：{{ url }}</span>
        <copy-btn class="ml-2 cursor-pointer" :copy-text="url" />
      </div>
    </div>
    <div slot="reference" class="qrcode-reference mr-5 cursor-pointer rounded-sm bg-fillColor-4 text-textColor-3 hover:text-textColor-5">
      <icon-qr class="flex-shrink-0" />
    </div>
  </Popover>
</template>

<script>
import { Popover } from 'element-ui'
import QrCode from 'qrcode'
import iconQr from '@/assets/svg/icon/iconQr.svg'
import { generatePreviewUrl } from "~/site/model/helper";
export default {
  name: 'QrcodeView',
  components: {
    Popover,
    iconQr
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      visible: false,
      url: '',
      dataUrl: ''
    }
  },
  methods: {
    onShow() {
      this.url = generatePreviewUrl(this.model, 15)
      const options = {
        margin: 3,
        type: 'image/jpeg'
      }
      QrCode.toDataURL(this.url, options,(err, res) => {
        if (res) {
          this.dataUrl = res
        } else {
          throw new Error(err)
        }
      });
    }
  }
}
</script>

<style lang="less">
.qrcode-view-popper {
  background-color: @widget-menu-bg;
  border: 1px solid @widget-menu-border;
  width: 220px;
  box-sizing: border-box;
}
.qrcode-reference{
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: @border-radius-base;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
