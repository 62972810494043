<template>
  <div class="site-divider m-default" :style="{marginTop:`${my}px`}"></div>
</template>

<script>
export default {
  name: 'SiteEditDivider',
  props: {
    my: {
      type: Number,
      default: 0
    }
  },
}
</script>

<style scoped lang="less">
.site-divider{
  padding: 20px 0 0 0;
  &:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: @handle-bg;
    position: absolute;
    left: 0;
  }
}
.m-default{
  margin-top: 24px;
}
</style>
