// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cover-wrap__btn{align-items:center;background-color:#3d46f5;border-radius:20px;color:#fff;cursor:pointer;display:flex;height:32px;justify-content:center;line-height:1;opacity:.4;padding:0 20px;transition:opacity .3s ease}.cover-wrap__btn:hover{opacity:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
