<template>
  <div class="edit-background-menu-wrap">
    <menu-title v-if="showTitle" :close="close" :f-size="14" :show-line="false" class="mb-[0px]">{{
        $t('menu.image')
      }}
    </menu-title>
    <menu-divider :my="8"/>
    <im-tip class="mt-3" text="上传图片大小不超过2M"/>
    <!-- 设置图像包含模式-->
    <menu-item v-if="imageKey && imageSizeKey && hasBgSizeKey()" :label="true ? '' : $t('edit.imageColor')"
               class="pt-3">
      <!-- <button v-for="bgSize in bgSizeList" :key="bgSize" class="bg-size-item" :class="{'bg-size-item-active': target[imageSizeKey] === bgSize}"
              @click="changeBackGroundSizeMethod(bgSize)">  {{$t('edit.' + bgSize)}} </button> -->
      <menu-select :icon='false' :label="''" :options="bgSizeList" :value="target[imageSizeKey]" class="w-full" wfull @change="changeBackGroundSizeMethod" />
    </menu-item>

    <menu-item v-if="imageKey" :label="true? '': $t('model.assets')" class="pt-[16px]">
      <!--<assets-material ref="assetsMaterial" in-widget :value="target[imageKey] || model[imageKey]" :limit="limit" :suggestion="suggestion" @change="imgChange"></assets-material>-->
      <assets-library ref="assetsLibrary" :limit="limit" :value="target[imageKey] || model[imageKey]" in-widget @change="imgChange"/>
    </menu-item>
  </div>
</template>

<script>
import { Message } from "element-ui";
import {BackgroundSizeList} from '~/enums/DomEnum'
import menuTitle from "~/components/menu/menuTitle";
import menuItem from "~/components/menu/menuItem";
import {insertStep} from "~/utils/event";
// import AssetsMaterial from '~/site/components/assetsMaterial';
import AssetsLibrary from '~/site/components/assetsLibrary/index.vue'
import MenuSelect from "~/components/menu/menuSelect";
import MenuDivider from "~/components/menu/divider"
import {getImageSize} from "~/utils";


export default {
  name: 'EditMenuPopperBackground',
  components: {
    menuTitle,
    menuItem,
    // AssetsMaterial,
    MenuSelect,
    MenuDivider,
    AssetsLibrary
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    size: {
      type: Array,
      default() {
        return []
      }
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    target: [Object, String],
    targetKey: {
      type: String,
      default: ''
    },
    targetIndex: {
      type: [String,Number],
      default: ''
    },
    imageSizeKey: {
      type: String,
      default: 'backgroundSize',
    },
    imageKey: {
      type: String,
      default: 'backgroundImage',
    },
    suggestion: {
      type: Object,
      default() {
        return null
      },
    },

    limit: {
      type: Number,
      default: 2 // 2Mb
    },
  },
  data() {
    return {
      bgSizeList: BackgroundSizeList.map((item) => {
        return {
          label: this.$t('edit.' + item),
          value: item
        }
      })
    }
  },
  computed: {},
  methods: {
    close() {
      this.$emit('close')
    },
    initAssetsMaterial() {
      // this.$refs.assetsMaterial.init()
    },
    changeBackGroundSizeMethod(value) {
      if (this.imageSizeKey !== 'backgroundSize') {
        this.$set(this.target, this.imageSizeKey, value)
      } else if (this.model.setBackgroundSize) {
        this.model.setBackgroundSize(value)
      } else if (this.target && this.target.setBackgroundSize) {
        this.target.setBackgroundSize(value)
      } else {
        this.$set(this.target, this.imageSizeKey, value)
      }
      this.$emit('size-change', value)
    },
    hasBgSizeKey() {
      return this.imageSizeKey && Object.prototype.hasOwnProperty.call(this.target, this.imageSizeKey)
    },
    async checkImgSize(url) {
      console.log(this.size)
      let result = false
      if (this.size.length < 1) {
        result = true
      } else {
        const res = await getImageSize(url)
        if (res?.height && res?.width) {
          console.log(res)
          result = res.width === this.size[0] && res.height === this.size[1]
        }
      }
      return result
    },
    async imgChange(url) {
      if (!await this.checkImgSize(url)) {
        Message.error(`设置失败，使用的图片尺寸需为${this.size[0]} * ${this.size[1]}`)
        return
      }
      const oldValue = this.target[this.imageKey]
      if (this.imageKey !== 'backgroundImage') {
        this.$set(this.target, this.imageKey, url)
      } else if (this.model.setBackgroundImage) {
        this.model.setBackgroundImage(url);
      } else if (this.target && this.target.setBackgroundImage) {
        this.target.setBackgroundImage(url)
      } else {
        this.$set(this.target, this.imageKey, url)
      }
      this.$emit('change', url)
      insertStep(this, [this.targetKey, this.targetIndex, this.imageKey], oldValue)
    }
  }
}
</script>

<style lang="less">
.edit-background-menu-wrap {
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;

  .bg-size-item {
    display: block;
    width: 100%;
    height: 32px;
    line-height: 32px;
    color: @text-color-secondary;
    background-color: @widget-menu-bg;
    text-align: center;
    border-radius: 2px;
  }

  .bg-size-item-active {
    color: @text-color;
    background-color: @primary-color-alpha-1;
  }
}
</style>
