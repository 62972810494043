<template>
  <div class="error-container">
    <h1 class="error-container__title"> 401</h1>
    <div class="redirect-content">
      Sorry, you cannot view this page. Please
      <a :href="url">log in to Publisher Account</a> to continue.
    </div>
    <div>
      <span v-if="code">[{{ code }}] </span>
      <span v-if="request">{{ request }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthFail",

  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      url: process.env.APP_URL,
      code: 0,
      request: ''
    }
  },

  mounted() {
    this.code = this.$route.query?.c
    this.request = this.$route.query?.u
  }
}
</script>

<style lang="less" scoped>
.error-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='1103' height='712'><path d='M720.52 210c-33.372.008-63.462-20.09-76.235-50.922-12.774-30.83-5.715-66.32 17.884-89.916 23.6-23.596 59.09-30.65 89.919-17.873 30.83 12.777 50.924 42.87 50.912 76.241-.124 45.5-36.98 82.351-82.48 82.47zm0-122.108a39.467 39.467 0 1 0 39.275 39.638c-.018-21.742-17.533-39.42-39.275-39.638zM415.852 712H364V533h51.852v179zM491 712h-51.84V533H491v179zM287.857 163.383L383.24 68.22 314.054 0l-94.682 95.117-.163-.268L150 163.138l.163.245-.163.151 122.968 122.223-94.519 94.267L246.934 449 411 285.466zM874.892 268L646 662h457L874.892 268zm.038 204.318l50.51 87.198H823.452l51.478-87.198zM148 496.309L120.903 469l-46.91 46.81L27.097 469 0 496.309l46.929 46.53L0 589.672 27.097 617l46.896-46.838L120.903 617 148 589.672l-46.781-46.833z' fill-opacity='.04'/></svg>") center center no-repeat;
  background-size: cover;
}

.error-container__title {
  margin-bottom: 8px;
  font-size: 100px;
  font-weight: bold;
  line-height: normal;
}

.error-container__message {
  margin-bottom: 24px;
  max-width: 500px;
  text-align: center;
}

.error-container__back {
  color: #0073f7;
  cursor: pointer;
  font-weight: 700;
}

a {
  color: #0073f7;
  text-decoration: none;
  text-decoration-line: none;
  text-decoration-style: initial;
  text-decoration-color: initial;
}
</style>
