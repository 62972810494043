<template>
  <div class="widget-support">
    <menu-switch :value="model.show" :label="$t('menu.supportOpen')" class="store-rich-text" @change="e=> model.show = e"></menu-switch>
    <menu-item :pt="'16px'" :border-bottom="true" class="menu-item-background" :label="$t('upload.text')">
      <menu-background-image :target="model"></menu-background-image>
    </menu-item>
    <menu-item :pt="'16px'" :border-bottom="true" class="menu-item-background" :label="$t('menu.shadowColor')">
      <menu-fill :target="model.shadow"></menu-fill>
    </menu-item>
    <menu-item :pt="'16px'" :border-bottom="true" class="menu-item-background" :label="$t('menu.layout')">
        <menu-select  :icon='false' :value="model.layout" :label="''" :options="layoutList" @change="change"></menu-select>
    </menu-item>
  </div>
</template>

<script>
// 属性编辑
import MenuItem from '~/components/menu/menuItem'
import menuBackgroundImage from '~/components/menu/menuBackgroundImage'
import menuFill from '~/components/menu/menuFill.vue'
import MenuSelect from "~/components/menu/menuSelect";
import MenuSwitch from '~/components/menu/menuSwitch'
import {LayoutEnum} from '~/enums/layoutEnum'


export default {
  name: 'ImageTextWidget',
  components: {
    MenuItem,
    menuBackgroundImage,
    menuFill,
    MenuSelect,
    MenuSwitch
  },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    site: {
      type: Object,
      default() {
        return {}
      },
    },
    model: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
        layoutList: Object.values(LayoutEnum).map((item) => {
        return {
          label: this.$t('edit.' + item),
          value: item
        }
      })
    }
  },
  mounted() {
  },
  methods: {
      change(direction) {
       this.model.layout = direction;
      }
  },
}
</script>

<style lang="less" scoped>
</style>
