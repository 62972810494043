<template>
  <div class="site-alert" :class="'site-alert-' + type">
    <im-icon v-if="type === 'success'" icon="icon-success"></im-icon>
    <im-icon v-if="type === 'error'" icon="icon-error"></im-icon>
    {{ message }}
    <slot></slot>
    <template v-if="showClose">
      <im-icon icon="icon-clear" class="icon-close" @click="close"></im-icon>
    </template>
  </div>
</template>

<script>
export default {
  name: "SiteAlert",
  props: {
    type: {
      type: String,
      default: 'success'
    },
    message: {
      type: [String, Object],
      default: ''
    },
    showClose: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.site-alert {
  border-radius: 2px;
  box-sizing: border-box;
  padding: 7px 7px;
  text-align: center;
  position: relative;
  white-space: nowrap;
  overflow: hidden;

  .icon-close {
    font-size: 14px;
    position: absolute;
    top: 10px;
    display: inline-block;
    right: 7px;
    cursor: pointer;
  }
}

.site-alert-success {
  background-color: @success-color-alpha-1;
  color: @success-color;
  font-size: 14px;
}

.site-alert-error {
  background-color: @opacity-error-color;
  color: @error-color;
  font-size: 14px;
}
</style>
