// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".convert-key-widget .m-0-auto{margin:0 auto}.convert-key-widget__content{margin:0 auto;max-width:1240px}.convert-key-widget__gift{align-items:center;display:flex;gap:10px;overflow:auto;width:100%}.convert-key-widget__gift .item{align-items:center;display:flex;flex-direction:column;flex-shrink:0;justify-content:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
