<template>
  <widget-content
    class="widget-footer"
    :class="isMobile && 'is-mobile'"
    :bg="model"
  >
    <div
      :class="
        isMobile
          ? 'footer_content_mobile relative z-10'
          : 'footer_content_desk relative z-10'
      "
      :style="{
        color: model.footerFontColorShow ? model.footerFontColor : 'unset',
      }"
    >
      <!-- logo & text -->
      <!--      <div class="footer_logo_text">-->
      <!--        &lt;!&ndash; logo &ndash;&gt;-->
      <!--        <div v-if="model.logoImageShow" class="footer_logo flex-center">-->
      <!--          <img-->
      <!--            v-if="model.logoImage || DefaultImage.Footer"-->
      <!--            :src="model.logoImage || DefaultImage.Footer"-->
      <!--            class="h-full rounded-sm"-->
      <!--            alt-->
      <!--          />-->
      <!--          <CoverBlock-->
      <!--            v-if="editing"-->
      <!--            :title="$t('edit.setImage')"-->
      <!--            class="logo_cover_block"-->
      <!--            @click="handleShowLogo()"-->
      <!--          />-->
      <!--        </div>-->
      <!--        &lt;!&ndash; text模块 &ndash;&gt;-->
      <!--        <template v-if="!isHaveImage">-->
      <!--          <div v-for="block in textImageData" :key="block.id" :model="block" class="footer_text">-->
      <!--            <rich-text-->
      <!--              v-model="block.text"-->
      <!--              :editing="editing"-->
      <!--              :disabled="!editing"-->
      <!--              theme="snow"-->
      <!--              placement="top"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </template>-->
      <!--        &lt;!&ndash; text image模块 兼容有图片的数据 &ndash;&gt;-->
      <!--        <div v-if="isHaveImage" class="footer_text_image">-->
      <!--          <div-->
      <!--            v-for="block in textImageData"-->
      <!--            :key="block.id"-->
      <!--            :model="block"-->
      <!--            class="item_text_image"-->
      <!--          >-->
      <!--            <FooterImage-->
      <!--              v-if="block.name === 'FooterImageWidgetModel'"-->
      <!--              :key="block.id"-->
      <!--              class="footer-image"-->
      <!--              :editing="editing"-->
      <!--              :max-height="60"-->
      <!--              :block="{-->
      <!--                 ...block,-->
      <!--                 logoImageShow: true-->
      <!--                }"-->
      <!--            />-->
      <!--            <rich-text-->
      <!--              v-if="block.name === 'FooterTextWidgetModel'"-->
      <!--              v-model="block.text"-->
      <!--              :editing="editing"-->
      <!--              :disabled="!editing"-->
      <!--              theme="snow"-->
      <!--              placement="top"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!-- lang select -->
      <div
        v-if="showLangSelect"
        class="footer_lang_select border-b border-b-fillColor-3"
      >
        <LangSelectComponent
          source="footer"
          :editing="editing"
          :device="device"
        ></LangSelectComponent>
      </div>
      <!-- 分割线 -->
      <!--      <div-->
      <!--        v-if="(model.logoImageShow || textImageData.length > 0) && (linkData.length > 0 || model.showCopyRight)"-->
      <!--        :class="['footer_split_line', model.showSplitLine ? 'split_line_block' : 'split_line_none']"-->
      <!--        :style="model.showSplitLine ? {backgroundColor: model.splitLineColor} : {}"-->
      <!--      />-->
      <div v-if="isHaveMedia" class="mt-6">
        <div
          v-for="item in mediaData"
          :key="item.id"
          class="pb-6 mt-2 border-b border-b-fillColor-3"
        >
          <div class="footer-media">{{ item.title }}</div>
          <div class="flex items-center mt-2">
            <bg-style
              v-for="(tag, index) in item.navs"
              :key="index"
              :bg="tag.logo"
              class="item w-[28px] h-[28px] mr-8 cursor-pointer"
              @click.native="handleClickToNav(tag)"
            />
          </div>
        </div>
      </div>
      <!-- quick link模块 -->
      <div v-if="linkData.length > 0" class="quick-link mt-9">
        <div
          v-for="(block, index) in linkData"
          :key="block.id"
          class="flex justify-between items-center flex-wrap mb-4"
        >
          <div class="quick-link__column">
            <div v-for="(item, navIndex) in block.navs" :key="navIndex" class="item mr-[60px] cursor-pointer text-14" @click="handleClickToNav(item)">{{ item.label }}</div>
          </div>
          <div v-if="index === linkData.length - 1" class="quick-link__logo">
            <img class="max-h-[40px]" :src="model.logoImage" alt="">
          </div>
        </div>
      </div>
      <!--      <template v-if="isMobile">-->
      <!--        <div-->
      <!--          v-for="block in linkData"-->
      <!--          :key="block.id"-->
      <!--          :model="block"-->
      <!--          class="flex flex-col items-center justify-center footer_link"-->
      <!--        >-->
      <!--          <FooterQuickLink :key="block.id" :model="block" :editing="editing" :is-mobile="isMobile" />-->
      <!--        </div>-->
      <!--        <div v-if="model.showCopyRight" class="flex items-center justify-center w-full mt-6">-->
      <!--          <rich-text-->
      <!--            v-model="model.copyRight"-->
      <!--            :editing="editing"-->
      <!--            :disabled="!editing"-->
      <!--            theme="snow"-->
      <!--            :no-tool-bar="true"-->
      <!--            :class="['text-[12px] m-0 p-0', {'min-w-[100px]': !model.copyRight}]"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </template>-->
    </div>
  </widget-content>
</template>

<script>
import { mapState } from 'vuex'
import { FooterWidgetModel } from '../../model/index'
import LangSelectComponent from '../../components/langSelect'
// import FooterQuickLink from './quickLink'
// import FooterImage from './image.vue'
import WidgetContent from '~/site/components/widgetContent'
// import RichText from '~/components/richText'
import { DefaultImage } from '~/enums/defaultImageEnum'
import { navTo } from '~/utils'
import {DeviceEnum} from "~/enums/deviceEnum";

export default {
  name: 'FooterWidget',
  components: {
    LangSelectComponent,
    // FooterQuickLink,
    WidgetContent,
    // FooterImage,
    // RichText,
  },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    site: {
      type: Object,
      default() {
        return {}
      },
    },
    model: {
      type: Object,
      default() {
        return new FooterWidgetModel()
      },
    },
    device: {
      type: String,
      default: 'desktop',
    },
  },
  data() {
    return {
      DefaultImage,
    }
  },
  computed: {
    DeviceEnum() {
      return DeviceEnum
    },
    ...mapState({
      realDevice: (state) => state.device.device,
    }),
    // 是否移动端
    isMobile() {
      const device = this.editing ? this.device : this.realDevice
      return device === 'mobile'
    },
    // 是否含有图片
    isHaveImage() {
      return this.model.children.some(
        (item) => item.name === 'FooterImageWidgetModel'
      )
    },
    isHaveMedia() {
      return this.model.children.some(
        (item) => item.name === 'FooterMediaWidgetModel'
      )
    },
    // 文本集合
    textImageData() {
      return (
        this.model.children.filter(
          (item) =>
            item.name === 'FooterTextWidgetModel' ||
            item.name === 'FooterImageWidgetModel'
        ) || []
      )
    },
    // 链接集合
    linkData() {
      return (
        this.model.children.filter(
          (item) => item.name === 'FooterQuickLinkWidgetModel'
        ) || []
      )
    },
    mediaData() {
      return this.model.children.filter(
        (item) => item.name === 'FooterMediaWidgetModel'
      )
    },
    showLangSelect() {
      return (
        this.site &&
        this.site.langSetting &&
        this.site.langSetting.langPosition === 'bottom'
      )
    },
  },
  methods: {
    footerTextChange(text, block) {
      this.$set(block, 'text', text)
      this.$set(block, 'isTextEdit', true)
    },
    handleShowLogo() {
      this.SiteMenu.closeShowImage('logo-image')
    },
    handleClickToNav(item) {
      if (this.editing) return
      navTo(this, item)
    },
  },
}
</script>

<style lang="less" scoped>
.widget-footer {
  color: var(--text-color);
  &.is-mobile {
    margin-top: -28px;
  }
}
.footer_content_desk {
  padding: 36px 0;
  .footer_logo {
    width: 100%;
    height: 40px;
    margin-bottom: 16px;
    .logo_cover_block {
      position: absolute !important;
      height: 40px !important;
    }
  }
  .quick-link__column {
    display: flex;
    align-items: center;
    column-gap: 60px;
  }
  .footer_lang_select {
    padding-bottom: 24px;
    .lang-select-component {
      padding: 7px;
      border: 1px solid white;
      border-radius: 2px;
    }
  }

  .footer_text {
    width: 660px;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .footer_text_image {
    display: flex;
    flex-wrap: wrap;
    .item_text_image {
      width: 20%;
      display: flex;
      justify-items: center;

      .footer-image {
        width: 100%;
        height: 60px;
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  .split_line_block {
    margin: 36px 0;
  }

  .split_line_none {
    margin: 18px 0;
  }

  .footer_link {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.footer_content_mobile {
  padding: 24px 16px;
  margin-top: 28px;
  .footer_lang_select {
    padding-bottom: 24px;
    .lang-select-component {
      padding: 4px;
      border: 1px solid white;
      border-radius: 2px;
    }
  }
  .quick-link__column {
    .item {
      margin-bottom: 12px;
      &:last-of-type {
        margin-bottom: 26px;
      }
    }
  }
  .quick-link__logo {
    width: 100%;
    padding-top: 24px;
    border-top: 1px solid @fill-color-3;
  }

  .footer_text {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .footer_text_image {
    .item_text_image {
      width: 100%;

      .footer-image {
        width: 100%;
        height: 60px;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .footer_link {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.footer_logo_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer_split_line {
  width: 100%;
  height: 1px;
  position: relative;
  z-index: 99;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
