// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lottery-history .el-dialog__header{font-weight:700;text-align:center}.lottery-history .el-dialog__header .el-dialog__title{font-size:18px}.lottery-history__content .list{max-height:400px;overflow-y:auto}.lottery-history__content .list .item{background-color:#f6f6f6;font-size:12px;margin-bottom:8px;padding:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
