// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header_avatar_popover{border:1px solid #ebeef1;border-radius:2px;padding:4px;width:210px}.header_avatar_popover .header_avatar_item{color:#18171c;font-family:PingFangSC-Regular;margin-bottom:2px}.header_avatar_popover .header_avatar_item:hover{background-color:rgba(235,238,241,.6)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
