var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"edit-background-menu-wrap"},[_c('menu-title',{attrs:{"close":_vm.close}},[_vm._v(_vm._s(_vm.$t('head.backImg')))]),_vm._v(" "),_c('menu-item',{attrs:{"label":_vm.$t('edit.peiSe')}},[_c('div',{staticClass:"flex color-list"},[_vm._l((_vm.colorList),function(item,index){return _c('div',{key:index,staticClass:"cursor-pointer color-item",style:({
        backgroundImage: _vm.model.backgroundImage?`url(${_vm.model.backgroundImage})`: 'none',
        backgroundSize: _vm.model.backgroundSize
      }),on:{"click":()=>{
         _vm.model.setBackgroundColor(item.value)
      }}},[_c('div',{staticClass:"color-bg",style:({
          backgroundColor: item.value
        })})])}),_vm._v(" "),_c('div',{staticClass:"relative flex items-center justify-center cursor-pointer color-item set",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleShowColor.apply(null, arguments)}}},[_c('set-svg',{staticClass:"fill-white"}),_vm._v(" "),_c('ColorPicker',{ref:"color",attrs:{"show-alpha":true,"index":1.6},on:{"change":(val)=>{
                       _vm.model.setBackgroundColor(val)
                     }},model:{value:(_vm.model.backgroundColor),callback:function ($$v) {_vm.$set(_vm.model, "backgroundColor", $$v)},expression:"model.backgroundColor"}})],1)],2)]),_vm._v(" "),_c('menu-item',{attrs:{"label":_vm.$t('edit.imageColor')}},_vm._l((_vm.bgSizeList),function(bgSize){return _c('button',{key:bgSize,staticClass:"bg-size-item",class:{'bg-size-item-active': _vm.model.backgroundSize === bgSize},on:{"click":function($event){return _vm.changeBackGroundSizeMethod(bgSize)}}},[_vm._v("  "+_vm._s(_vm.$t('edit.' + bgSize))+" ")])}),0),_vm._v(" "),_c('menu-item',{attrs:{"label":_vm.$t('model.assets')}},[_c('assets-material',{attrs:{"in-widget":"","value":_vm.model.backgroundImage,"limit":5},on:{"change":(url)=>{
      _vm.model.setBackgroundImage(url)
    }},model:{value:(_vm.model.backgroundImage),callback:function ($$v) {_vm.$set(_vm.model, "backgroundImage", $$v)},expression:"model.backgroundImage"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }