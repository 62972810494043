<template>
  <div class="menu-switch flex items-center justify-between bg-fillColor-2 rounded-sm p-2" :style="style">
    <div class="label flex items-center">
      <div class="label__circle mr-2" />
      <span>{{ label }}</span>
    </div>
    <el-switch v-if="!disabled" v-model="val" :active-color="activeColor" :inactive-color="inactiveColor" @change="onChange"></el-switch>
  </div>
</template>

<script>
import { Switch } from 'element-ui'
import {insertStep} from "~/utils/event";

export default {
  name: "MenuSwitch",
  components: {
    ElSwitch: Switch,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: '开关'
    },
    targetKey: {
      type: String,
      default: ''
    },
    activeColor: {
      type: String,
      default: '#13ce66'
    },
    inactiveColor: {
      type: String,
      default: '#464852'
    },
    marginBottom: {
      type: String,
      default: '8px'
    },
    value: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      val: this.value,
    }
  },
  computed: {
    style () {
      return {
        marginBottom: this.marginBottom
      }
    }
  },
  watch: {
    value() {
      this.val = this.value
    }
  },
  created() {
    this.val = this.value;
  },
  methods: {
    onChange(val) {
      insertStep(this, [this.targetKey], this.value)
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
</script>

<style lang="less" scoped>
.menu-switch {
  height: @base-height;
  .label {
    font-size: 12px;
    color: @text-5;
    font-weight: normal;
    &__circle {
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: @primary-color;
    }
  }
}
</style>
