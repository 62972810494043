<template>
  <div ref="colorModel" class="color-model rounded">
    <div class="color-model__header flex items-center justify-between border-b p-2 border-fillColor-2">
      <im-select v-model="type" class="w-2/4" size="small" :options="typeOptions" @change="onChangeType"/>
      <i class="el-icon-close cursor-pointer text-18 text-white" @click="handleClose" />
<!--      <div class="close-btn rounded-full flex items-center justify-center text-textColor-3 hover:text-white" @click="handleClose">-->
<!--        <im-icon class="text-20 cursor-pointer" icon="icon-clear" />-->
<!--      </div>-->
    </div>
    <div class="color-model__content px-3 py-3">
      <gradient v-if="type === 'gradient'" ref="gradient" v-model="color" class="mb-3" :parent-rect="rect" @change="onGradientChange" @pure-change="onGradientPureChange" />
      <pure v-if="type === 'pure'" v-model="color" @change="onPureChange" />
    </div>
<!--    <div class="color-model__recent p-2 flex items-center gap-4 flex-wrap min-h-[34px]">-->
<!--      <div v-for="(item, index) in recentColors" :key="index" class="color-item rounded-sm cursor-pointer" :style="{ backgroundColor: item }" @click="handleClickRecent(item)" />-->
<!--    </div>-->
    <color-transform :value="transformValue" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import colorTransform from './transform.vue'
import gradient from './gradient'
import pure from "./pure";
export default {
  components: {
    gradient,
    colorTransform,
    pure
  },
  props: {
    value: [String]
  },
  data() {
    return {
      type: '',
      color: '',
      transformValue: '',
      rect: {
        bottom: null,
        height: null,
        left: null,
        right: null,
        top: null,
        width: null,
        x: null,
        y: null
      },
      typeOptions: [
        {
          label: '纯色',
          value: 'pure'
        },
        {
          label: '线性渐变',
          value: 'gradient'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['recentColors'])
  },
  watch: {
    value(n) {
      this.color = n
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    handleClickRecent(value) {
      if (this.type === 'pure') {
        this.color = value
        this.emitValue(value)
      } else if (this.type === 'gradient') {
        this.$refs.gradient.setCurrentValue(value)
      }
    },
    init() {
      const rect = this.$refs.colorModel.getBoundingClientRect()
      for (const key in rect) {
        if (Object.prototype.hasOwnProperty.call(this.rect, key)) {
          this.rect[key] = rect[key]
        }
      }
      this.initValue()
    },
    onChangeType() {
      this.color = this.value
    },
    initValue() {
      this.transformValue = this.value
      this.color = this.value
      this.getValueType()
    },
    getValueType() {
      const linearKey = 'linear'
      const isLinear = this.value.includes(linearKey)
      if (isLinear) {
        this.type = 'gradient'
      } else {
        this.type = 'pure'
      }
    },
    handleClose() {
      this.$emit('close')
    },
    emitValue(value) {
      this.$emit('change', this.type, value)
    },
    onGradientPureChange(value) {
      this.transformValue = value
    },
    onGradientChange(value) {
      this.transformValue = value
     this.emitValue(value)
    },
    onPureChange(value) {
      this.transformValue = value
      this.emitValue(value)
    }
  }
}
</script>

<style lang="less">
.color-model {
  background-color: @widget-menu-bg;
  width: 220px;
  .vc-chrome-fields-wrap {
    display: none;
  }
  &__header {
    .im-select {
      .el-input__inner {
        background-color: @widget-menu-bg;
      }
      &:hover {
        .el-input__inner {
          background-color: @fill-color-2;
          border-color: transparent;
        }
      }
    }
    .close-btn {
      width: 18px;
      height: 18px;
      background-color: @fill-color-2;
      font-weight: bold;
    }
  }
  &__recent {
    .color-item {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
