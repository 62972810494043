<template>
    <div class="mobile-header flex justify-between items-center">
        <div class="left" @click="back">
            <back-result-svg v-if="from === 'result'"></back-result-svg>
            <back-svg v-else></back-svg>
        </div>
        <div class="center">
            <slot></slot>
        </div>
        <div class="right"></div>
    </div>
</template>
<script>
import backSvg from '~/assets/svg/icon/back-mobile.svg';
import backResultSvg from '~/assets/svg/icon/back-mobile-result.svg'
export default {
    name: 'MobileHead',
    components: {
        backSvg,
        backResultSvg,
    },
    props: {
        from: {
            type: String,
            default: ''
        }
    },
    methods: {
        back () {
            this.$emit('back')
        }
    }

}
</script>
<style lang="less" scoped>
.mobile-header {
    display: flex!important;
    height: 44px;
    padding: 0 16px;
    .left {
        cursor: pointer;
    }
    .center {
        font-weight: 500;
        font-size: 18px;
        color: #1F1D29;
    }
}
</style>
