<template>
    <div v-if="show" class="im-model-wrap pay-result">
        <div class="im-model">
            <div class="im-model-head">
                <div class="top-info flex justify-center items-center">
                    {{title}}
                </div>
            </div>
            <div class="im-model-body">
                <slot></slot>
            </div>
            <div class="im-model-foot">
                <div class="btn-wrap flex justify-center">
                    <div v-if="cancelText" class="btn" @click="show=false; $emit('close')">{{cancelText}}</div>
                    <div v-if="okText" class="btn" @click="$emit('ok')">{{okText}}</div>
                </div>
            </div>
            <span class="close" @click="show=false">
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_289_32894)">
                    <circle cx="9.5" cy="9" r="9" fill="white" fill-opacity="0.6"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5397 5.76788L6.26656 13.041L5.35742 12.1318L12.6305 4.85874L13.5397 5.76788Z" fill="#5C6FFE"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.26104 4.96523L13.5341 12.2383L12.625 13.1475L5.3519 5.87436L6.26104 4.96523Z" fill="#5C6FFE"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_289_32894">
                    <rect width="18" height="18" fill="white" transform="translate(0.5)"/>
                    </clipPath>
                    </defs>
                </svg>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default:'',
        },
        okText: {
            type: String,
            default:'',
        },
        cancelText: {
            type: String,
            default:'',
        }
    },
    data () {
        return {
            show: false,
        }
    },
    watch: {
        value (newVal, oldVal) {
            this.show = newVal;
        },
        show (val) {
            this.$emit('input', val)
        }
    },
    mounted () {
        this.show = this.value;
    },
    methods: {       
        
    }
}
</script>
<style lang="less" scoped>
    .im-model-wrap {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2021;
        background: rgba(25, 27, 66, 0.8);
        .im-model {
            // background: #fff;
            position: absolute;
            top: 25%;
            left: 50%;
            transform: translate(-50%, -30%);
            .close {
                position: absolute;
                right: 18px;
                top: 10px;
                font-style: normal;
                width: 18px;
                height: 18px;
                cursor: pointer;
            }
            .im-model-head {
                height: 48px;
                background: #222445;
                padding:0 24px 0 24px;
                .top-info {
                    height: 48px;
                    font-weight: 500;
                    font-size: 18px;
                    color: #fff;
                }
            }
            .im-model-body {
                background: #1B1C31;
                padding: 16px;
                min-width: 600px;
            }
            .im-model-foot {
                background: #1B1C31;
                text-align: center;
                padding-bottom: 40px;
                padding-top: 20px;
                .btn-wrap {
                    .btn {
                        font-size: 16px;
                        width: 100px;
                        height: 40px;
                        line-height: 40px;
                        color: rgba(255, 255, 255, 0.6);
                        border-radius: 90px;
                        border: 1px solid rgba(255, 255, 255, 0.3);
                        cursor: pointer;
                        margin-left: 24px;
                    }
                    .btn:last-child {
                        background: #5C6FFE;
                        color: #fff;
                        margin-left: 0;
                        border: none;
                    }
                }
            }
        }
    }
</style>