<template>
  <div class="month-card-edit">
    <menu-item label="月/周卡">
      <im-select v-model="model.cardId" class="w-full" size="small" :options="$store.state.goods.cardList" />
    </menu-item>
    <menu-item label="背景设置">
      <menu-background-image :target="model.background" target-key="background" />
      <menu-fill class="mt-2" :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="布局">
      <menu-switch v-model="model.titleVisible" label="标题" />
      <menu-switch v-model="model.subtitleVisible" class="mt-2" label="描述" />
    </menu-item>
    <menu-item label="Banner图">
      <menu-background-image :target="model.banner" target-key="banner" event-key="month-card-banner" />
    </menu-item>
    <menu-item label="购买按钮">
      <menu-background-image :target="model.buyBackground" target-key="buyBackground" />
      <menu-fill class="mt-2" :target="model.buyBackground" target-key="buyBackground" />
    </menu-item>
    <menu-item label="购买按钮文字">
      <menu-fill class="mt-2" :target="model.buyText" target-key="buyText" />
    </menu-item>
    <menu-item label="可领取按钮">
      <menu-background-image :target="model.getBackground" target-key="getBackground" />
      <menu-fill class="mt-2" :target="model.getBackground" target-key="getBackground" />
    </menu-item>
    <menu-item label="可领取文字">
      <menu-fill class="mt-2" :target="model.getText" target-key="getText" />
    </menu-item>
    <menu-item label="已领取按钮">
      <menu-background-image :target="model.receiveBackground" target-key="receiveBackground" />
      <menu-fill class="mt-2" :target="model.receiveBackground" target-key="receiveBackground" />
    </menu-item>
    <menu-item label="已领取文字">
      <menu-fill class="mt-2" :target="model.receiveText" target-key="receiveText" />
    </menu-item>
    <menu-item label="道具数量文字">
      <menu-fill class="mt-2" :target="model.goodsNumberBackground" target-key="goodsNumberBackground" />
    </menu-item>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem.vue";
import ImSelect from "~/components/common/ImSelect.vue";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue";
import MenuFill from "~/components/menu/menuFill.vue";
import MenuSwitch from "~/components/menu/menuSwitch.vue";
import RemoveBtn from "~/components/common/RemoveBtn.vue";

export default {
  name: 'MonthCardWidgetEdit',
  components: {RemoveBtn, MenuSwitch, MenuFill, MenuBackgroundImage, ImSelect, MenuItem},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      options: []
    }
  },
  created() {
    //
  },
  methods: {
  }
}
</script>

<style scoped lang="less">

</style>
