<template>
  <div class="edit-widget-columns">
    <div v-for="(item, index) in settings" :key="item.getMenuInfo().name" :class="{ [`columns-item-${index}`]: true, 'active': current === index, 'prev': index === (current - 1), 'next': index === (current + 1) }" class="item" @click.stop="handleItemClick(item, index)">
      <component :is="item.getMenuInfo().icon" class="text-20" />
      <div class="name text-white">{{ item.getMenuInfo().name }}</div>
    </div>
    <div class="bottom-setting" :class="{ 'active': current === settings.length - 1 }">
      <div class="item" @click.stop="handleAppSettingClick">
        <icon-setting class="text-20" />
        <div class="name text-white">{{ SiteColumnsNameEnum.SETTING }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import pageSvg from '@/assets/svg/icon/page-column.svg'
import iconLoginNor from '@/assets/svg/icon/iconLoginNor.svg'
import iconSetting from '@/assets/svg/icon/iconSetting.svg'
import iconGlobal from '@/assets/svg/icon/iconGlobal.svg'
import { SiteColumnsNameEnum } from "~/enums/siteEnum"

export default {
  name: 'WidgetColumns',
  components: {
    pageSvg,
    iconLoginNor,
    iconSetting,
    iconGlobal
  },
  props: {
    settings: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      SiteColumnsNameEnum,
      current: 0
    }
  },
  computed: {
  },
  methods: {
    closeMenus() {
      this.SiteMenu.close()
      this.$store.commit('user/CLEAR_SITE_LOGIN_USER_MODAL')
    },
    handleItemClick(item, index) {
      if (this.SiteMenu.columnIndex === index) return
      this.closeMenus()
      this.SiteMenu.setColumnIndex(index)
      this.SiteMenu.setColumnChildIndex(null)
      this.current = index
    },
    handleAppSettingClick() {
      this.$emit('show-app-setting')
    }
  }
}
</script>

<style lang="less">
.edit-widget-columns {
  width: @edit-columns-w;
  height: calc(100vh - 56px);
  padding: 0 0 30px 0;
  position: relative;
  flex-shrink: 0;
  background-color: @widget-menu-bg;
  border-right: 1px solid @widget-menu-border;
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100px;
    cursor: pointer;
    background-color: @menu-bg;
    position: relative;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    color: white;
    &.active {
      background-color: @widget-menu-bg;
      &:after {
        content: '';
        position: absolute;
        right: -1px;
        top: -3px;
        width: 1px;
        background-color: #1E1E1E;
        height: 105px;
      }
    }
    &.next {
      border-top-right-radius: 4px;
      border-top: 1px solid @widget-menu-border;
    }
    &.prev {
      border-bottom-right-radius: 4px;
      border-bottom: 1px solid @widget-menu-border;
    }
    .pic {
      width: 28px;
      height: 28px;
      background-color: white;
      border-radius: 2px;
    }
    .name {
      color: @menu-text-color;
      margin-top: 5px;
      font-size: 12px;
    }
  }
  .bottom-setting {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(100vh - 300px - @header-height);
    left: 0;
    background-color: @menu-bg;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &.active {
      border-top-right-radius: 4px;
      border-top: 1px solid @widget-menu-border;
    }
  }
}
</style>
