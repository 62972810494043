<template>
  <div
    :style="{border: isFocus? '0.5px solid rgba(255, 255, 255, 0.6)': '' }"
    class="menu-fill flex items-center bg-fillColor-2 rounded-sm px-2 border border-transparent hover:border-primary relative">
    <div class="flex items-center w-[90px]">
      <popover v-model="visible" :visible-arrow="false" placement="left-start" popper-class="menu-fill-popper" trigger="click" @after-enter="onPopperShow">
        <div slot="reference" class="header-look rounded cursor-pointer overflow-hidden">
          <div :style="{ background: target[colorKey] }" class="inner w-full h-full"/>
        </div>
        <div v-if="visible">
          <color-model ref="colorModel" :value="target[colorKey]" @change="onColorModelChange" @close="onColorModelClose"/>
        </div>
      </popover>
      <span class="header-name text-textColor-3 text-12 ml-2">
      {{ colorName }}
    </span>
    </div>
    <div class="opacity-input">
      <el-input v-model="alpha" class="w-full" size="mini" @blur="onAlphaChange" @focus="onAlphaFocus" @keyup.native="onAlphaKeyup"/>
    </div>
    <div v-if="eye && hasEyeKey()" class="eye-change flex items-center justify-center absolute right-0 top-0 text-textColor-7 hover:text-textColor-5 hover:bg-fillColor-2 cursor-pointer" @click="handleToggleVisible">
      <component :is="isShow ? 'icon-show' : 'icon-hide'" class="text-20"/>
    </div>
  </div>
</template>

<script>
import {Popover, Input} from 'element-ui'
import debounce from 'lodash.debounce'
import tinycolor from "tinycolor2";
import iconShow from '@/assets/svg/icon/iconShow.svg'
import iconHide from '@/assets/svg/icon/iconHide.svg'
import colorModel from "~/components/colorModel";
import {getAlpha} from "~/utils/colors";
import {insertStep} from "~/utils/event";

export default {
  components: {
    Popover,
    ElInput: Input,
    iconShow,
    iconHide,
    colorModel
  },
  props: {
    colorKey: {
      type: String,
      default: 'backgroundColor'
    },
    eyeKey: {
      type: String,
      default: 'backgroundColorShow'
    },
    targetKey: {
      type: String,
      default: ''
    },
    targetIndex: {
      type: [Number, String],
      default: ''
    },
    eye: {
      type: Boolean,
      default: true
    },
    target: {
      type: Object,
      default() {
        return {}
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      visible: false,
      alpha: this.value,
      isFocus: false
    }
  },
  computed: {
    colorName() {
      return this.isLiner ? 'Linear' : tinycolor(this.target[this.colorKey]).toHex()
    },
    isLiner() {
      const value = this.target[this.colorKey] || ''
      return value.includes('linear-gradient')
    },
    isShow() {
      return this.target[this.eyeKey]
    }
  },
  mounted() {
    this.formatAlpha()
    this.SiteMenu.$bus.$on('updateAlpha', () => {
      this.formatAlpha()
    })
  },
  methods: {
    onAlphaChange(event) {
      this.isFocus = false;
      const value = event.target.value.replace('%', '')
      const color = tinycolor(this.target[this.colorKey])
      const rgba = color.setAlpha(value / 100).toRgbString()
      if (!this.alpha.includes('%')) {
        this.alpha += '%'
      }
      if (!this.isLiner) {
        this.setValue(rgba)
      }
    },
    onAlphaFocus(event) {
      this.isFocus = true;
      event.target.setSelectionRange(0, event.target.value.length)
    },
    onAlphaKeyup() {
      this.alpha = this.alpha.replace(/[^\d^.%]/g, '')
    },
    onPopperShow() {
      this.$refs.colorModel && this.$refs.colorModel.init()
    },
    handleToggleVisible() {
      const {eyeKey} = this
      insertStep(this, [this.targetKey, this.targetIndex, this.eyeKey], this.target[eyeKey])
      this.target[eyeKey] = !this.target[eyeKey]
    },
    hasEyeKey() {
      return this.eyeKey && Object.prototype.hasOwnProperty.call(this.target, this.eyeKey)
    },
    formatAlpha() {
      const alpha = getAlpha(this.target[this.colorKey])
      this.alpha = typeof alpha === 'number' ? Math.round(alpha * 100) + '%' : '100%'
    },
    onColorModelClose() {
      this.visible = false
    },
    setValue(value) {
      const oldValue = this.target[this.colorKey]
      insertStep(this, [this.targetKey, this.targetIndex, this.colorKey], oldValue)
      if (this.colorKey !== 'backgroundColor') {
        this.target[this.colorKey] = value
      } else if (this.target && this.target.setBackgroundColor) {
        this.target.setBackgroundColor(value, this.colorKey)
      } else {
        this.target[this.colorKey] = value
      }
    },
    debounceSetValue: debounce(function (color) {
      this.setValue(color)
      this.formatAlpha()
      this.$emit('change', color)
    }, 500),
    onColorModelChange(type, color) {
      this.setValue(color)
      this.formatAlpha()
      this.$emit('change', color)
    }
  }
}
</script>

<style lang="less">
@imgBg: "data:image/svg+xml;utf8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M0%200H3V3H0V0Z%22%20fill%3D%22%23E1E1E1%22/%3E%3Cpath%20d%3D%22M3%200H6V3H3V0Z%22%20fill%3D%22white%22/%3E%3Cpath%20d%3D%22M3%203H6V6H3V3Z%22%20fill%3D%22%23E1E1E1%22/%3E%3Cpath%20d%3D%22M0%203H3V6H0V3Z%22%20fill%3D%22white%22/%3E%3C/svg%3E%0A";
.menu-fill-popper {
  padding: 0;
  border: 0;
  margin-right: 30px !important;
  background-color: @popover-bg;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.36);
}

.menu-fill {
  height: 32px;
  transition: all 0.4s ease;

  .header-look {
    width: 20px;
    height: 20px;
    background-image: url(@imgBg);
  }

  .opacity-input {
    width: 46px;

    .el-input__inner {
      padding: 0 0 0 10px;
      background-color: transparent;
      border: 0;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .eye-change {
    width: 28px;
    height: 100%;
  }
}
</style>
