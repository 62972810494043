<template>
  <div class="color-model__pure">
    <color-picker v-model="color" @input="onInput" />
  </div>
</template>

<script>
import colorPicker from './picker'
export default {
  components: {
    colorPicker
  },
  props: {
    value: [String]
  },
  data() {
    return {
      color: this.value
    }
  },
  watch: {
    value(n) {
      this.color = n
    }
  },
  mounted() {
    this.color = this.value
  },
  methods: {
    onInput(color) {
      this.$emit('change', color)
    }
  }
}
</script>

<style scoped>

</style>
