// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".edit-background-menu-wrap{box-sizing:border-box;overflow:hidden;padding:10px}.edit-background-menu-wrap .bg-size-item{background-color:#1e1e1e;border-radius:2px;color:hsla(0,0%,100%,.6);display:block;height:32px;line-height:32px;text-align:center;width:100%}.edit-background-menu-wrap .bg-size-item-active{background-color:#282944;color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
