<template>
  <el-dialog class="month-card-rule" :visible.sync="visible" :width="$store.getters.isMobile ? '100%' : '640px'" :title="$t('siteBuild.lotteryWidget.ruleTitle')">
    <div class="content">
      <p>{{ rule1Text }}</p>
      <p>{{ model.rule2 }}</p>
      <p>{{ model.rule3 }}</p>
      <p>{{ model.rule4 }}</p>
      <p>{{ model.rule5 }}</p>
      <p>{{ model.rule6 }}</p>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog } from "element-ui";

export default {
  name: 'MonthCardRule',
  components: {
    [Dialog.name]: Dialog
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    rule1Text() {
      return this.model.rule1.replace('{0}', this.model.card_type === 1 ? '30' : '7')
    }
  },
  methods: {
    init() {
      this.visible = true
    }
  }
}
</script>

<style lang="less">
.month-card-rule {
  .el-dialog__header {
    text-align: center;
  }
  .content {
    word-break: break-word;
  }
}
</style>
