<template>
  <div class="guide-panel p-4">
    <menu-title>自定义您的模板</menu-title>
    <p>选择侧边栏中的分区或块并开始。</p>
    <site-edit-divider></site-edit-divider>
    <div class="title mt-4 text-16">键盘快捷键</div>
    <div class="guide-panel__quick">
      <div class="flex items-center mt-4">
        <div class="item mr-2">⌘</div>
        <div class="item">Z</div>
        <span class="ml-2">撤销</span>
      </div>
      <div class="flex items-center mt-4">
        <div class="item mr-2">⌘</div>
        <div class="item">X</div>
        <span class="ml-2">恢复</span>
      </div>
<!--      <div class="flex items-center mt-4">-->
<!--        <div class="item mr-2">⌘</div>-->
<!--        <div class="item">I</div>-->
<!--        <span class="ml-2">移动端/桌面端</span>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import MenuTitle from "~/components/menu/menuTitle.vue"
import SiteEditDivider from "~/components/menu/divider.vue"

export default {
  components: {SiteEditDivider, MenuTitle},
  data() {
    return {}
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.guide-panel {
  &__quick {
    .item {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @handle-bg;
      border-radius: 4px;
    }
  }
}
</style>
