// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".add-block-wrap.mobile .add-block-opener{top:-15px}.add-block-opener{align-items:center;border:1px dashed transparent;border-radius:2px;color:var(--text-color);cursor:pointer;display:flex;justify-content:center;left:0;margin:0 auto;max-width:820px;padding:5px 0;position:absolute;right:0;text-align:center;text-transform:uppercase;top:-20px;width:100%;z-index:40}.add-block-opener:hover{border:1px dashed #3f54f2}.add-block-opener__btn{align-items:center;background-color:#3f54f2;border-radius:32px;color:#fff;display:flex;font-size:.7em;letter-spacing:.1em;line-height:32px;opacity:.4;padding:0 16px;transition:opacity .2s ease}.add-block-opener__btn:hover{opacity:1}.add-block{box-sizing:border-box;height:0;max-height:0;opacity:0;overflow:hidden;text-align:center;width:100%}.add-block.no-padding{padding:0}.add-block__title{color:#fff}.add-block__items{margin-top:24px;max-width:100%;overflow:auto;overflow-y:hidden;padding:0 24px 3rem}.add-block__item,.add-block__items{align-items:center;display:flex;justify-content:center}.add-block__item{cursor:pointer;flex-direction:column;margin-right:80px;max-width:200px;opacity:.5;transition:opacity .2s ease}.add-block__item:last-child{margin-right:0}.add-block__item:hover{opacity:1}.add-block__item-img{background-color:hsla(0,0%,100%,.6);border-radius:100%;height:80px;width:80px}.add-block__item-title{color:#fff;font-size:14px;margin-top:8px;text-align:center}.add-block.add-block__open{background-color:#3f54f2;height:226px;max-height:226px;opacity:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
