<template>
  <div class="cover-wrap" @click="$emit('click')">
    <div class="cover-wrap__btn">
      <add-green />
      <span class="ml-8px">{{ title }}</span>
    </div>
  </div>
</template>

<script>
import addGreen from '~/assets/svg/icon/add-green.svg'
export default {
  name: 'CoverBtn',
  components: {
    addGreen
  },
  props: {
    title: {
      type: String,
      default: '设置图像'
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
  }
}
</script>

<style lang="less">
.cover-wrap {
  &__btn {
    height: 32px;
    padding: 0 20px;
    color: white;
    opacity: 0.4;
    background-color: @primary-color;
    border-radius: 20px;
    transition: .3s ease opacity;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
