<template>
  <div class="ui-global-menu">
    <im-icon class="im-icon text-18" :icon="menuIcon" />
    <label>{{$t(`model.${model.title}`)}}</label>
  </div>
</template>

<script>
import ImIcon from "~/components/common/ImIcon";
export default {
  name: "UiGlobalMenu",
  components:{
    ImIcon
  },
  props:{
    index:{
      type: Number,
      default: 0,
    },
    selected: {
      type: Boolean,
      default: false
    },
    model:{
      type: Object,
      required: true,
      default(){
        return {
          id: '',
          icon: '',
          title: '',
        }
      }
    }
  },
  computed: {
    menuIcon() {
      return this.selected ? (this.model.iconSelected || this.model.icon) : this.model.icon
    }
  },
  methods:{

  }
}
</script>

<style lang="less" scoped>
.ui-global-menu{
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //flex-direction: row;
  cursor: pointer;
  vertical-align: middle;
  height: 52px;
  line-height: 52px;
  padding-left: 20px;
  .iconfont{
    display: inline-block;
    vertical-align: top;
  }
   label{
     margin-left: 9px;
   }

}
</style>
