<template>
 <div>
    <el-input v-model="model.title"></el-input>
 </div>
</template>

<script>
import { TitleWidgetModel } from '~/site/model/models/base'
export default {
  name: "TitleWidgetEdit",
  props:{
    model: {
      type: Object,
      default(){
        return new TitleWidgetModel()
      }
    }
  }
}
</script>

<style scoped>

</style>
