<template>
  <div class="notice-edit">
    <menu-item :label="$t('edit.customBg')">
      <upload-block :suggestion="{w: 980,h:48}" @click.native="handleShowBackground({w: 980,h:48})"></upload-block>
    </menu-item>
    <p class="tip mt-24px help-color">
      公告内容通过调用我们的接口传递过来。<br>
      公告的开启和关闭通过接口中传递的参数来控制，默认不展示。
    </p>
  </div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem";
import UploadBlock from "~/components/common/uploadBlock";
export default {
  name: 'NoticeWidgetEdit',
  components: {UploadBlock, MenuItem},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {
    handleShowBackground(suggestion) {
      this.$parent.selectSubmenu('background', this.model, { colorKey: 'backgroundColor', imageKey: 'backgroundImage', suggestion })
    }
  }
}
</script>

<style lang="less">
.notice-edit {
}
</style>
