<template>
  <el-input v-model="val" type="text" class="im-input" :class="{'disabled': disabled}" v-bind="{...$props, ...$attrs}"
            :clearable="clearable" size="small" @keyup="keyup" @change="change" @focus="focus" @blur="blur"/>
</template>
<script>
import { Input } from 'element-ui'
export default {
  components: {
    ElInput: Input
  },
  props: {
    value: null,
    autocomplete: {
      type: String,
      default: 'new-password',
    },
    numberOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      val: null,
    }
  },
  watch: {
    value() {
      this.val = this.value;
    }
  },
  mounted() {
    this.val = this.value;
  },
  methods: {
    change() {
      this.$emit('input', this.val);
      this.$emit('change', this.val)
    },
    keyup() {
      this.$emit('input', this.val);
      this.$emit('keyup', this.val);
      // 仅输入数字
      if (this.numberOnly) {
        this.val = this.val.replace(/[^0-9]/g, '');
      }
    },
    focus() {
      this.$emit('focus')
    },
    blur() {
      this.$emit('blur')
    }
  }
}
</script>
<style lang="less">
.im-input {
  .el-input__inner {
    border: 1px solid transparent;
    background-color: @fill-color-2;
    color: @text-5;
  }
  &::placeholder {
    color: #C9C9D4;
    font-size: 14px;
  }

  &:focus {
    outline: none;
    border: 1px solid #5A47EE;
  }

  &.disabled {
    background: #F7F8FA;
    color: #C9C9D4;
  }
}

</style>
