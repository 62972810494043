<template>
 <div class="widget-title">
    {{model.title}}
 </div>
</template>

<script>
// 属性编辑
export default {
  name: "AssetsSetting",
}
</script>

<style lang="less" scoped>
.widget-title{
  padding: 200px 8px;
  color: var(--text-color)
}
</style>
