// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editor-wrap{max-width:1224px;width:100%;z-index:10}.editor-wrap .code-editor .CodeMirror-gutters,.editor-wrap .code-editor .cm-s-ayu-dark.CodeMirror{background-color:rgba(10,30,20,.8)}.editor-wrap .code-editor .cm-s-ayu-dark .CodeMirror-activeline-background{background-color:rgba(1,6,14,.8)}.editor-wrap .code-editor .CodeMirror{border-radius:2px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
