<template>
  <div class="color-model__gradient" :style="gradientStyle">
    <div ref="thumb" class="thumb-area flex items-center" @mousedown.prevent="handleAddColorItem">
      <div
        v-for="(item, index) in colors"
        :key="index"
        :ref="`item${index}`"
        class="color-item absolute cursor-pointer"
        :class="{ 'active': currentIndex === index }"
        :style="{ transform: `translate3d(${item.left}px, 0, 0)` }"
        @mousedown.stop="dragStart($event, index)"
      >
        <div class="inner h-full flex">
          <div class="h-full w-1/2 left-color" :style="{ backgroundColor: colors[index] ? colors[index].origin : ''}"></div>
          <div class="w-1/2 h-ull" :style="{ backgroundColor: colors[index] ? colors[index].value : ''}" />
        </div>
        <div class="triangle"/>
      </div>
    </div>
    <div class="color-bar mt-2 rounded-sm relative">
      <div class="color-bar__inner rounded-sm absolute w-full h-full top-0 left-0"></div>
    </div>
    <div class="color-mark flex relative">
      <div v-for="(item, index) in colors" :key="index" class="mark-item flex items-center justify-center absolute" :style="{ transform: `translate3d(${item.left}px, 0, 0)` }">
        <div class="h-[5px] w-[1px] bg-textColor-3" />
      </div>
    </div>
    <color-picker v-if="colors[currentIndex]" v-model="colors[currentIndex].value" @input="onColorInput"  />
    <color-picker v-else v-model="placeColor" />
  </div>
</template>

<script>
import tinycolor from 'tinycolor2'
import colorPicker from './picker'
import {on, off} from "~/utils/dom";
import { formatValue } from '~/components/colorPickers/utils'
export default {
  components: {
    colorPicker
  },
  props: {
    value: [String],
    parentRect: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      placeColor: '',
      colors: [],
      currentIndex: 0,
      itemRect: {
        width: 24
      },
      thumbRect: {},
      maxLeft: '',
      offsetLeft: '',
      downX: '',
      isDragging: false
    }
  },
  computed: {
    gradientStyle() {
      const colors = JSON.parse(JSON.stringify(this.colors))
      const deg = 90
      let value = ''
      colors.sort((a, b) => a.left - b.left)
      colors && colors.forEach((item, index) => {
        const comma = index === colors.length - 1 ? '' : ','
        value += `${item.value} ${item.percent}% ${comma}`
      })
      return {
        '--osw-gradient-background': `linear-gradient(${deg}deg, ${value})`
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  destroyed() {
    off(document.body, 'keyup', this.deleteColorItemByKeyup)
  },
  methods: {
    setCurrentValue(value) {
      if (this.currentIndex > -1) {
        this.colors[this.currentIndex].value = value
        this.colors[this.currentIndex].origin = this.getOriginColor(value)
        this.emitValue()
      }
    },
    deleteColorItemByKeyup(event) {
      if (event.keyCode === 8 && this.currentIndex > -1) {
        this.colors.splice(this.currentIndex, 1)
        this.currentIndex = -1
      }
    },
    init() {
      this.thumbRect = this.$refs.thumb.getBoundingClientRect()
      this.maxLeft = this.thumbRect.width - this.itemRect.width
      on(document.body, 'keyup', this.deleteColorItemByKeyup)
      this.getValue()
    },
    getOriginColor(value) {
      const color = tinycolor(value)
      return color.setAlpha(1).toRgbString()
    },
    getPercentWidth(percent) {
      const itemWidth = this.itemRect.width
      const value = Math.round((this.thumbRect.width - itemWidth) * (percent / 100))
      if (value <= 0) {
        return 0
      } else if (value >= this.thumbRect.width) {
        return value - itemWidth
      }
      return value
    },
    getValue() {
      const colors = formatValue(this.value)
      if (colors && colors.length) {
        this.colors = colors.map(item => {
          const newItem = {...item}
          newItem.left = this.getPercentWidth(newItem.percent)
          newItem.origin = this.getOriginColor(newItem.value)
          return newItem
        })
      } else {
        this.setDefault()
      }
    },
    setDefault() {
      const { maxLeft } = this
      this.colors = [
        {
          left: 0,
          percent: 0,
          origin: '',
          value: ''
        },
        {
          left: maxLeft,
          percent: 100,
          origin: '',
          value: ''
        }
      ]
    },
    emitValue() {
      const that = this
      this.$emit('change', that.gradientStyle['--osw-gradient-background'])
    },
    emitPureValue() {
      this.$emit('pure-change', this.colors[this.currentIndex].value)
    },
    onColorInput(value) {
      this.colors[this.currentIndex].origin = this.getOriginColor(value)
      this.emitValue()
      this.emitPureValue()
    },
    getClickPercent(clientX) {
      return Number(((clientX - this.thumbRect.left) / this.thumbRect.width * 100).toFixed(2))
    },
    handleAddColorItem(event) {
      const itemWidth = 24
      const percent = this.getClickPercent(event.clientX)
      const maxLeft = this.thumbRect.width - itemWidth
      const downX = event.clientX - this.thumbRect.left
      const left = downX >= maxLeft ? maxLeft : (downX - itemWidth / 2)
      const item = {
        value: '',
        origin: '',
        percent,
        left
      }
      this.colors.push(item)
      this.currentIndex = this.colors.length - 1
      this.emitValue()
    },
    dragMove(event) {
      event.preventDefault()
      if (!this.isDragging) return
      const {clientX} = event
      const { currentIndex, maxLeft } = this
      const moveX = clientX - this.downX
      const left = moveX + this.offsetLeft - this.itemRect.width / 2
      let current = 0
      if (left <= 0) {
        current = 0
      } else if (left >= maxLeft) {
        current = this.maxLeft
      } else {
        current = left
      }

      this.colors[currentIndex].percent = Math.round((current / this.maxLeft) * 100)
      this.colors[currentIndex].left = current
      this.emitValue()
    },
    dragEnd() {
      off(document.body, 'mousemove', this.dragMove)
      off(document.body, 'mouseup', this.dragEnd)
    },
    dragStart(event, index) {
      const {clientX} = event
      this.currentIndex = index
      this.itemRect = this.$refs[`item${index}`][0].getBoundingClientRect()
      this.maxLeft = this.thumbRect.width - this.itemRect.width
      this.offsetLeft = this.itemRect.left - this.parentRect.left
      this.downX = clientX
      this.isDragging = true
      on(document.body, 'mousemove', this.dragMove)
      on(document.body, 'mouseup', this.dragEnd)
      this.emitPureValue()
    }
  }
}
</script>

<style lang="less">
.color-model__gradient {
  @itemBg: #2C2A37;
  @imgBg: "data:image/svg+xml;utf8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M0%200H3V3H0V0Z%22%20fill%3D%22%23E1E1E1%22/%3E%3Cpath%20d%3D%22M3%200H6V3H3V0Z%22%20fill%3D%22white%22/%3E%3Cpath%20d%3D%22M3%203H6V6H3V3Z%22%20fill%3D%22%23E1E1E1%22/%3E%3Cpath%20d%3D%22M0%203H3V6H0V3Z%22%20fill%3D%22white%22/%3E%3C/svg%3E%0A";
  .thumb-area {
    height: 24px;
    .color-item {
      width: 24px;
      height: 24px;
      border: 2px solid @fill-color-2;
      background-color: @itemBg;
      padding: 4px;

      .inner {
        background-color: black;
        background-image: url(@imgBg);
        .left-color {

        }
        .right-bg {
          background-image: url(@imgBg);
        }
      }

      .triangle {
        width: 0;
        height: 0;
        border-top: 5px solid #373541;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 5px);

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-top: 3px solid @itemBg;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          position: absolute;
          top: -6px;
          left: -3px;
        }
      }

      &.active {
        border-color: @primary-color;
        z-index: 10;
        .triangle {
          border-top: 5px solid @primary-color;
        }
      }
    }
  }

  .color-bar {
    height: 16px;
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
    background-color: white;
    background-image: url(@imgBg);
    &__inner {
      background: var(--osw-gradient-background);
    }
  }

  .color-mark {
    height: 20px;
    .mark-item {
      width: 24px;
      height: 20px;
    }
  }
}
</style>
