<template>
  <div class="edit-allow-country-setting">
    <menu-item label="您可以配置国家，这些国家将无法访问您的网站。" style="margin-bottom: 16px;">
    </menu-item>
    <div class="country-tag-list">
      <tag-item v-for="(c,index) in countryList" :key="index" class="country-tag-item" :value="c.value"
                :label="c.label"
                :type="getType(c)"
                @close="close"
                @add="add"
      ></tag-item>
    </div>

  </div>
</template>

<script>
import {AllowSettingModel} from '../../model';
import MenuItem from "~/components/menu/menuItem";
import TagItem from '~/components/menu/menuTag'
import { useAssets } from '~/hooks/useAssets'

export default {
  name: "AllowSettingEdit",
  components: {
    MenuItem,
    TagItem,
  },
  props: {
    model: {
      type: Object,
      default() {
        return new AllowSettingModel()
      }
    }
  },
  data(){
    const {countryList} = useAssets(this)
    return {
      countryList
    }
  },
  async created() {
    const { countryList,getCountryList} = useAssets(this)
    await getCountryList()
    this.countryList = countryList
  },
  // computed:{
  //
  // },
  methods:{
    getType(c){
      return this.model.allowCountry.includes(c.value)? 'active': 'default'
    },
    close(val){
      this.model.deleteCountry(val)
    },
    add(val){
      this.model.addCountry(val)
    }
  }
}
</script>

<style lang="less" scoped>
.edit-allow-country-setting {
  .country-tag-list{
    display: flex;
    flex-wrap: wrap;
    .country-tag-item{
      margin-top: 8px;
      margin-right: 4px;
    }
  }

}
</style>
