// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu-item{position:relative}.menu-item .label__circle{background-color:#3d46f5;border-radius:100%;height:4px;width:4px}.menu-item .menu-item{padding-top:0}.menu-item.menu-item-pt{padding-top:24px}.menu-item.border-b-line:after{background-color:hsla(0,0%,100%,.1);bottom:0;content:\"\";height:1px;left:-15px;position:absolute;width:240px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
