// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".im-input .el-input__inner{background-color:hsla(0,0%,100%,.05);border:1px solid transparent;color:hsla(0,0%,100%,.9)}.im-input::-moz-placeholder{color:#c9c9d4;font-size:14px}.im-input::placeholder{color:#c9c9d4;font-size:14px}.im-input:focus{border:1px solid #5a47ee;outline:none}.im-input.disabled{background:#f7f8fa;color:#c9c9d4}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
