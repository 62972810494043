<template>
 <div>
  <!--  notthsing -->
 </div>
</template>

<script>
// 属性编辑
export default {
  name: "GlobalSettingWidget",

}
</script>

<style scoped>

</style>
