<template>
 <div class="widget-title">
    {{model.title}}
 </div>
</template>

<script>
// 属性编辑
import { TitleWidgetModel } from '~/site/model/models/base'
export default {
  name: "GlobalSettingWidgetEdit",
  props:{
    model:{
      type: Object,
      default(){
        return new TitleWidgetModel()
      }
    },
    editing:{
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
.widget-title{
  padding: 200px 8px;
  color: var(--text-color)
}
</style>
