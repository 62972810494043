<template>
  <div class="upload-block-wrap  cursor-pointer rounded" :class="{ 'ghost': ghost }"
  >
    <div class="flex items-center justify-center">
      <div v-show="bg"  class="upload-block-wrap-bg" :style="{backgroundImage: bg?`url(${bg})`: 'none'}"></div>
      <upload-svg class="upload-svg"/>
      <slot v-if="ghost"><span class="name">{{$t('edit.uploadPic')}}</span></slot>
    </div>

    <div  v-if="suggestion && suggestion.w && suggestion.h" class="suggestion text-center">
      {{$t('upload.suggestion',suggestion)}}
    </div>
  </div>
</template>

<script>
import uploadSvg from '~/assets/svg/icon/upload.svg'

export default {
  name: 'UploadBlock',
  components: {
    uploadSvg
  },
  props: {
    bg:{
      type: String || undefined,
      default:  '',
    },
    ghost: {
      type: Boolean,
      default: false
    },
    suggestion: {
      type: Object,
      default(){
        return {
          w: 0,
          h: 0
        }
      }
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less">
.upload-block-wrap {
  padding: 16px 8px 12px 8px;
  position: relative;
  background-color: @primary-color-light;

  .upload-block-wrap-bg{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    opacity: 0.6;
    filter: opacity(0.6);
  }
  .upload-svg {
    fill: @primary-color;
  }

  &.ghost {
    border: 1px dashed @border-color-base;
    background-color: transparent;
    justify-content: flex-start;
    padding: 0 18px;
    .name {
      color: @text-color-secondary;
      margin-left: 8px;
    }
  }
  .suggestion{
    color: @primary-color-alpha-6;
    margin-top: 12px;
  }
}
</style>
