<template>
  <button class="rich-button" :style="getStyle" @click.stop>
    <div class="rich-button__text">
      {{ data }}
    </div>
  </button>
</template>

<script>
export default {
  name: 'RichButton',
  props: {
    value: {
      type: String,
      default: ''
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: this.value
    }
  },
  computed: {
    getStyle() {
      return {
      }
    }
  },
  watch: {
    value(n) {
      this.data = n
    }
  },
  methods: {
    onBlur(event) {
      const { target } = event
      this.$emit('input', target.textContent)
    }
  }
}
</script>

<style lang="less">
.rich-button {
  &__text {
    position: relative;
    width: 100%;
    max-width: 100%;
    min-width: 70px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: var(--main-font);
    line-height: 1.2em;
    text-align: center;
    text-overflow: ellipsis;
    color: var(--button-text-color);
  }
  &__text-editor {
  }
}
</style>
