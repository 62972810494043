<template>
  <div class="flex items-center currency-input">
    <div class="currency-input__label">{{ currency }}</div>
    <input v-model="data" class="currency-input__inner" @keyup="data=String(data).replace(/^(0+)|[^\d]+/g,'')" @input="onInput">
  </div>
</template>

<script>
export default {
  name: 'CurrencyInput',
  props: {
    currency: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      data: this.value
    }
  },
  watch: {
    value(value) {
      this.data = value
    }
  },
  methods: {
    onInput() {
      this.$emit('input', this.data)
    }
  }
}
</script>

<style scoped lang="less">
.currency-input {
  height: 36px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  &__label {
    height: 36px;
    background-color: white;
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: 800;
    line-height: 36px;
    text-align: center;
    color: rgba(41, 84, 252, 1);
    padding: 0 5px;
  }
  &__inner {
    width: 94px;
    background-color: white;
    border: none;
    outline: none;
    height: 36px;
    padding: 6px 10px;
    font-size: 24px;
    font-weight: bold;
    color: rgba(41, 84, 252, 1);
    border-radius: 0 4px 4px 0;
  }
  &.is-mobile {
    height: 22px;
    border-radius: 2px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .currency-input__label {
      height: 22px;
      background-color: white;
      font-size: 12px;
      font-weight: 800;
      line-height: 22px;
      text-align: center;
    }
    .currency-input__inner {
      width: 56px;
      background-color: white;
      border: none;
      outline: none;
      height: 22px;
      padding: 4px 7px;
      font-size: 12px;
      font-weight: bold;
      border-radius: 0 2px 2px 0;
    }
  }
}
</style>
