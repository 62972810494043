// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".edit-background-menu-wrap .color-list{gap:8px}.edit-background-menu-wrap .color-list .color-item{background-position:50%;background-repeat:no-repeat;background-size:100% 100%;border:.5px solid hsla(0,0%,100%,.3);border-radius:2px;height:33px;position:relative;width:33px}.edit-background-menu-wrap .color-list .color-item .color-bg{height:100%;left:0;position:absolute;top:0;width:100%}.edit-background-menu-wrap .color-list .color-item.active{border:1px solid #5c6ffe}.edit-background-menu-wrap .color-list .color-item:hover{border-color:#3d46f5}.edit-background-menu-wrap .color-list .color-item.set{background-color:#333}.edit-background-menu-wrap .color-list .color-item .color-picker{height:100%;left:0;pointer-events:none;position:absolute;top:0;visibility:hidden;width:100%}.edit-background-menu-wrap .bg-size-item{background-color:#1e1e1e;border-radius:2px;color:hsla(0,0%,100%,.6);display:block;height:32px;line-height:32px;text-align:center;width:100%}.edit-background-menu-wrap .bg-size-item-active{background-color:#282944;color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
