<template>
  <div>
    <MenuTitle :close="close">{{$t('model.footerImage')}}</MenuTitle>
    <menu-item class="pt-0" :label="$t('customImage')">
      <menu-background-image :target="target" image-key="image" eye-key="" image-opacity-key="" :suggestion="{
         extra: $t('upload.extra31')
      }"/>
    </menu-item>
    <DeleteBlockDialogTrigger
      @delete-block="deleteBlock"
    >
    </DeleteBlockDialogTrigger>
  </div>
</template>

<script>
import {FooterImageWidgetModel} from '../../model'
import FooterMixin from './mixinEdit'
import menuBackgroundImage from "~/components/menu/menuBackgroundImage";

export default {
  name: "FooterImageWidgetEdit",
  components: {
    menuBackgroundImage,
  },
  mixins: [FooterMixin],
  props: {
    target: {
      type: Object,
      default() {
        return new FooterImageWidgetModel()
      }
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
