<template>
    <div class="sub-title-wrap">
        <div class="sub">
            <p :style="fontStyles">{{title}}</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SubTitle',
    props: {
        title: {
            type: String,
            default: '标题',
        },
        margin: {
            type: String,
            default: '12px',
        },
        fontStyle: {
            type: Object,
            default: () => {
                return {
                    fontSize: '18px',
                    color: '#5C6FFE',
                    fontWeight: 500
                }
            }
        }
    },
    computed: {
        fontStyles () {
            const style = {
                fontSize: '18px',
                color: '#5C6FFE',
                fontWeight: 400
            }
            return Object.assign(style, this.fontStyle)
        }
    }
}
</script>
<style lang="less" scoped>
    .sub-title-wrap {
        position: relative;
        p {
            padding: 0;
            font-size: 18px;
            color: @primary-color;
            font-weight: 400;
        }
        .sub {
            height: 18px;
            line-height: 18px;
            padding-left: 12px;
        }
        .sub::before {
            display: block;
            content: '';
            width: 4px;
            height: 18px;
            background: @primary-color;
            border-radius: 1px;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
</style>
