<template>
  <div v-if="model.show" class="support-widget">
    <client-only>
      <ScrollShow :target="'#site-body'" v-bind="layout" @click="onClick">
        <div
          class="support-icon w-full h-full cursor-pointer"
          :style="{
            filter: `drop-shadow(0px 7px 17.5px ${backgroundColor})`,
          }"
        >
          <bg-style class="w-full h-full cursor-pointer" :bg="model">
          </bg-style>
        </div>
      </ScrollShow>
    </client-only>
  </div>
</template>
<script>
import ScrollShow from '~/site/components/scrollShow'
import { LayoutEnum } from '~/enums/layoutEnum'
import { insertScript } from '~/utils/dom'

export default {
  components: {
    ScrollShow,
  },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    site: {
      type: Object,
      default() {
        return {}
      },
    },
    model: {
      type: Object,
      default() {
        return {}
      },
    },
    device: {
      type: String,
      default: 'desktop',
    },
  },
  data() {
    return {
      init: false,
      dis: 0,
    }
  },
  computed: {
    backgroundColor() {
      return this.model?.shadow?.backgroundColor || '';
    },

    layout() {
      if (!this.init) {
        return {}
      }
      if (typeof document === 'undefined') {
        return {}
      }
      const body = document.querySelector('#site-body')
      if (!body) {
        return {}
      }
      let iconWidth = 70
      let hor = 48
      if (this.device === 'desktop') {
        hor = 153
        iconWidth = 140
      } else {
        hor = 48
        iconWidth = 70
      }
      const rect = body.getBoundingClientRect()
      const width = rect.width
      const left = rect.left
      let distance = 0
      if (this.model.layout === LayoutEnum.RIGHT) {
        distance = left + width - hor - iconWidth
      } else {
        distance = left + hor
      }
      return {
        left: distance,
        bottom: 48,
      }
    },
  },
  watch: {},
  mounted() {
    this.init = true
    document.addEventListener('message', function (event) {
      // if(event.data.type === 'newChatCount'){
      // 	//event.data.count 为 SDK 回调的未读消息数量
      // 	//获取到 event.data.count 后可以根据您的需求自行设计怎么展示到页面
      // 	self.count = event.data.count;
      // }
    })
  },
  methods: {
    onClick() {
      insertScript(
        {
          id: 'aihelp-js',
          src: './script/aihelp.js',
        },
        () => {
          const userConfig = {
            userId:  '123',
            userName: '未命名——',
            userTags: '',
            customData: {},
          }
          const supportConfig = {
            conversationIntent: 1,
            alwaysShowHumanSupportButtonInBotPage: true,
            welcomeMessage: '',
          }
          const initConfig = {
            appKey: 'IM30_app_476e403d729b4999908c89d312e3125d',
            domain: 'aihelp.net',
            appId: 'IM30_platform_a18ce42f-3a41-4975-b880-8239cd6f9fe7',
            appName: '微信商城',
            language: 'zh_CN',
            supportMode: 'showOperation',
            supportConfig,
            userConfig,
          }
          if (typeof AIHelpSupport !== 'undefined') {
            window.AIHelpSupport.init(initConfig)
            window.AIHelpSupport.show()
          }
        }
      )
    },
  },
}
</script>
<style lang="less" scoped>
.support-icon {
  border-radius: 50%;
  overflow: hidden;
}
</style>
