<template>
  <div class="convert-key-edit">
    <menu-item label="背景设置">
      <menu-fill :target="model.background" target-key="background" />
      <menu-background-image class="mt-2" :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="布局">
      <menu-switch v-model="model.titleVisible" label="标题" />
      <menu-switch v-model="model.subtitleVisible" label="描述" />
      <menu-switch v-model="model.giftVisible" label="是否显示奖品" />
    </menu-item>
    <remove-btn />
  </div>
</template>

<script>
import MenuFill from "~/components/menu/menuFill.vue"
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue"
import MenuItem from "~/components/menu/menuItem.vue"
import MenuSwitch from "~/components/menu/menuSwitch.vue"
import RemoveBtn from "~/components/common/RemoveBtn.vue"

export default {
  name: "ConvertKeyEdit",
  components: {RemoveBtn, MenuSwitch, MenuItem, MenuBackgroundImage, MenuFill},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="less">

</style>
