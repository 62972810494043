<template>
  <div class="copy-btn" @click="handleCopy">
    <im-icon icon="icon-copy" />
  </div>
</template>

<script>
import { Message } from 'element-ui'
export default {
  props: {
    copyText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods: {
    async handleCopy() {
      await this.$utils.copyText(this.copyText).catch(err => {
        Message.error(err)
      })
      Message.success('拷贝成功')
    }
  }
}
</script>

<style scoped>

</style>
