<template>
  <div>
<!--    <iframe ref="content" class="w-[800px] h-[800px]" src="http://127.0.0.1:3501/pay?token=v2eyJlIjoiNmVlOGJjN2VjODkxMTdhMjA1OTJhNWQ1M2YxMjVjNmRjMzc0MDBiNTg2ZWFjMWE2MWM1OWM2MmZiYTlhYzljMyIsInVybCI6Imh0dHBzOi8vc2VjdXJlLnRlc3Qua29wZ2xvYmFsLmNvbS9uZXcta29wL3BheWVsZW1lbnQifQ=="></iframe>-->
    <iframe ref="content" src="http://127.0.0.1:3501/new-kop/airwallex/card" />
    <iframe src="http://127.0.0.1:3501/new-kop/airwallex/expiry" />
    <button class="mt-10 w-[100px] h-[32px] flex items-center justify-center border" @click="handlePay">支付</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    handlePay() {
      const $content = this.$refs.content
      $content.contentWindow.postMessage({ type: 'submit' }, '*')
      console.log('发送消息')
    }
  }
}
</script>

<style scoped lang="less">

</style>
